/*global ApeConfig*/

import React from "react";
import { widget } from "../../lib/charting_library";
import Datafeed from "../../datafeed/datafeed";
import { useParams } from "react-router-dom";
import { useSockets } from "../context/SocketsContext";

function getLanguageFromURL() {
	const regex = new RegExp('[\\?&]lang=([^&#]*)');
	const results = regex.exec(window.location.search);
	return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

export const Chart = ({ symbol, style }) => {
	const chartContainerRef = React.useRef();
	const { latestEvent } = useSockets();

	React.useEffect(() => {
		try {
			Datafeed.liveUpdate(latestEvent);
		}
		catch (ex) {
			console.error(ex);
		}
	}, [latestEvent]);

	const defaultProps = {
		interval: '1',
		//datafeedUrl: 'https://demo_feed.tradingview.com',
		libraryPath: '/lib/charting_library/',
		seconds_resolution: true,
		//chartsStorageUrl: 'https://saveload.tradingview.com',
		//chartsStorageApiVersion: '1.1',
		clientId: 'tradingview.com',
		userId: 'public_user_id',
		fullscreen: false,
		autosize: true,
		studiesOverrides: {},
	};
	//enabled: ["iframe_loading_compatibility_mode", "study_templates", "items_favoriting", "datasource_copypaste", "seconds_resolution"]
	//disabled: ["auto_enable_symbol_labels", "header_symbol_search", "save_shortcut", "header_saveload", "header_fullscreen_button", "symbol_search_hot_key", "popup_hints", "legend_inplace_edit"];

	React.useEffect(() => {
		const widgetOptions = {
			symbol: symbol,
			datafeed: Datafeed,
			interval: defaultProps.interval,
			container: chartContainerRef.current,
			library_path: defaultProps.libraryPath,
			theme: 'dark',
			locale: getLanguageFromURL() || 'en',
			disabled_features: ["auto_enable_symbol_labels", "header_symbol_search", "save_shortcut", "header_saveload", "header_fullscreen_button", "symbol_search_hot_key", "popup_hints", "legend_inplace_edit"],
			enabled_features: ["iframe_loading_compatibility_mode", "items_favoriting", "datasource_copypaste", "seconds_resolution"],
			//charts_storage_url: defaultProps.chartsStorageUrl,
			//charts_storage_api_version: defaultProps.chartsStorageApiVersion,
			client_id: defaultProps.clientId,
			user_id: defaultProps.userId,
			fullscreen: defaultProps.fullscreen,
			autosize: defaultProps.autosize,
			studies_overrides: defaultProps.studiesOverrides,
		};

		const tvWidget = new widget(widgetOptions);

		tvWidget.onChartReady(() => {
			tvWidget.headerReady().then(() => {
				//const button = tvWidget.createButton();
				//button.setAttribute('title', 'Click to show a notification popup');
				//button.classList.add('apply-common-tooltip');
				//button.addEventListener('click', () => tvWidget.showNoticeDialog({
				//	title: 'Notification',
				//	body: 'TradingView Charting Library API works correctly',
				//	callback: () => {
				//		console.log('Noticed!');
				//	},
				//}));

				//button.innerHTML = 'Check API';
			});
		});

		return () => {
			tvWidget.remove();
		};
	}, [symbol]);

	return (
		<div
			ref={chartContainerRef}
			className={'TVChartContainer'}
			style={style}
		/>
	);
}

export const DexScreenerChart = ({ token, style }) => {
	const { tokenChain } = useParams();
	const config = ApeConfig.find(x => x.Short === tokenChain || x.ID == tokenChain);

	return (
		<div style={style}>
			<div id="dexscreener-embed" style={{ position: "relative", width: "100%", height: "100%" }}>
				<iframe id="tv-chart" style={{ position: "absolute", width: "100%", height: "100%", top: 0, left: 0, border: 0 }} src={`https://dexscreener.com/${config.Short === "eth" ? "ethereum" : config.Short}/${token.pairAddress}?embed=1&theme=dark&trades=0&info=0`}></iframe>
			</div>
		</div>
	);
}