/* global Version */
import React from 'react';
import axios from "axios";
import * as signalR from "@microsoft/signalr";

const SocketsContext = React.createContext();

export const SocketsProvider = ({ children, mobile }) => {
	const [connected, setConnected] = React.useState(false);
	const [events, setEvents] = React.useState([]);
	const [refreshRequired, setRefreshRequired] = React.useState(false);
	const [latestEvent, setLatestEvent] = React.useState(null);
	const [latestChat, setLatestChat] = React.useState(null);
	const [latestToken, setLatestToken] = React.useState(null);

	const disconnectionTime = React.useRef();
	const hub = React.useRef();

	const loadStaleData = (full) => {
		const loadEvents = async () => {
			const events = await hub.current.invoke("GetLatestEvents");
			setEvents(events);
		}

		const checkVersion = async () => {
			const response = await axios.get(`/api/version`);
			if (Version && response.data.version !== Version) {
				console.log("Version updated reload required.");
				window.location.reload();
			}
		}

		loadEvents();
		checkVersion()

		if (full) {
			console.log("reload page required");
			setRefreshRequired(true);
		}
	};

	const subscribeToken = async (chain, address) => {
		if (hub.current) {
			console.log(`Subscribing to ${chain} \\ ${address}`)
			await hub.current.invoke("SubscribeToken", chain, address);
			console.log(`Subscribed`);
		}
	}

	const unsubscribeToken = async (chain, address) => {
		if (hub.current) {
			console.log(`Unsubscribing from ${chain} \\ ${address}`)
			await hub.current.invoke("UnsubscribeToken", chain, address);
			console.log(`Unsubscribed`)
		} 
	}

	const latestKey = async () => {
		return await hub.current.invoke("Heartbeat");
	}

	React.useEffect(() => {
		if (latestEvent != null && latestEvent?.transaction?.bump) {
			setEvents(e => [latestEvent, ...e.slice(0, 9)]);
			setLatestToken({ ...latestEvent.token, flash: latestEvent?.transaction?.bump });
		}
	}, [latestEvent]);

	React.useEffect(() => {

		const connectSignalR = async () => {
			hub.current = new signalR.HubConnectionBuilder()
				.withUrl(
					`/eventHub`
				)
				.withAutomaticReconnect()
				.build();

			hub.current.on("Event", (newEvent) => {
				setLatestEvent(newEvent)
			});

			hub.current.on("Chat", (newChat) => {
				console.log(newChat)
				setLatestChat(newChat)
			});

			hub.current.onreconnecting(function () {
				disconnectionTime.current = disconnectionTime.current ?? new Date().getTime() / 1000;
			});

			hub.current.onreconnected(function () {
				const seconds = (new Date().getTime() / 1000) - disconnectionTime.current;
				disconnectionTime.current = null;
				console.log(`Disconnected for ${seconds}s`)
				loadStaleData(true);
			});

			hub.current.onclose(async () => {
				await hub.current.start();
				loadStaleData(true);
			});

			await hub.current.start();
			setConnected(true);
		};

		if (connected === false) {
			connectSignalR();
		} else {
			loadStaleData(false);
		}

		return async () => {
			if (hub.current && connected) {
				await hub.current.stop();
				setConnected(false);
			}
		}
	}, [connected]);

	return (
		<SocketsContext.Provider value={{ events, latestToken, latestEvent, latestKey, mobile, refreshRequired, setRefreshRequired, latestChat, subscribeToken, unsubscribeToken }}>
			{children}
		</SocketsContext.Provider>
	);
};

export const useSockets = () => {
	return React.useContext(SocketsContext);
};