import { MyApes } from "./components/MyApes";
import { NewToken } from "./components/NewToken";
import { Home } from "./components/Home";
import { Profile } from "./components/Profile";
import { ViewToken } from "./components/ViewToken";
import { Bridge } from "./components/Bridge";

const AppRoutes = [
	{
		index: true,
		path: '/',
		element: <Home />
	},
	{
		path: '/my-apes',
		element: <MyApes />
	},
	{
		path: '/new-token',
		element: <NewToken />
	},
	{
		path: '/profile',
		element: <Profile />
	},
	{
		path: '/bridge',
		element: <Bridge />
	},
	{
		path: '/:tokenChain/:tokenAddress',
		element: <ViewToken />
	}
];

export default AppRoutes;
