/*global ApeConfig*/

import React from 'react';
import axios from 'axios';
import { formatEther, parseEther } from 'ethers/utils';
import { BrowserProvider, Contract } from 'ethers'
import { notification } from 'antd';
import { Col, Row, Spin, Table, Typography, Flex, Card, Avatar, Button, Divider, Statistic, Tooltip, Pagination } from 'antd';
import { ClockCircleOutlined, DollarOutlined } from '@ant-design/icons';
import { ConnectButton } from './shared/ConnectButton';
import { UserIcon, Stats, NameDisplay } from './shared/UserIcon';
import { useWeb3ModalProvider, useWeb3ModalAccount } from '@web3modal/ethers/react'
import { BlockiesSvg } from 'blockies-react-svg'
import { ApeStorePrizeVaultAbi } from './shared/ApeStoreContract'

const { Link, Text } = Typography;
const { Column } = Table;
const { Countdown } = Statistic;

const Position = ({ index }) => {
	if (index === 0) {
		return <>🏆</>
	}
	if (index === 1) {
		return <>🥈</>
	}
	if (index === 2) {
		return <>🥉</>
	}
	return <>#{index + 1}</>
}

const Leaderboard = ({ leaderboard }) => {
	const formatNumber = (value) => {
		return Intl.NumberFormat('en-US', {
			notation: "compact",
			maximumFractionDigits: 4
		}).format(value);
	};

	if (!leaderboard) {
		return <Spin size="large" style={{ margin: 'auto' }} />
	}

	// TODO - not locked to chain
	const config = ApeConfig.find(x => x.ID == 8453); //ApeConfig.find(x => x.Short === tokenChain.toLowerCase() || x.ID == tokenChain);

	return (
		<Card title="✨ Leaderboard" rootClassName="table-card">
			<Table dataSource={leaderboard} pagination={false}>
				<Column title="Profile" dataIndex="address" key="address" render={(x, row, i) => (
					<Link href={`${config.ScannerUrl}/address/${x}`} target="_blank">
						<Flex align="center" gap={2}>
							<Position index={i} />
							<UserIcon style={{ minWidth: '20px', height: '20px', width: '20px' }} address={x} />
							{row.name ?
								<Text style={{ color: '#FFE814' }}>{row.name}</Text> :
								<Text style={{ color: '#FFE814' }}>{`${x.substr(0, 6)}...${x.substr(38, 4)}`}</Text>
							}
						</Flex>
					</Link>
				)} />
				<Column title="Referral Volume" key="xxcv" render={(_, row) => (
					<>${formatNumber(row.usdVolume)}</>
				)} />
			</Table>
		</Card>
	);
}

const CompetitionHistory = ({ latestCompetition }) => {
	const [competitionIndex, setCompetitionIndex] = React.useState(latestCompetition - 1)
	const [results, setResults] = React.useState(null);

	const formatNumber = (value) => {
		return Intl.NumberFormat('en-US', {
			notation: "compact",
			maximumFractionDigits: 4
		}).format(value);
	};

	React.useEffect(() => {
		const loadResults = async () => {
			setResults(null);
			const response = await axios.get(`/api/refer/8453/competition/${competitionIndex}`);
			setResults(response.data);
		}

		if (competitionIndex >= 0) {
			loadResults();
		}
	}, [competitionIndex]);

	if (latestCompetition <= 0) {
		return <></>;
	}	

	// TODO - not locked to chain
	const config = ApeConfig.find(x => x.ID == 8453); //ApeConfig.find(x => x.Short === tokenChain.toLowerCase() || x.ID == tokenChain);

	return (
		<Card title={`${config.Name} Referral Competition #${competitionIndex + 1} Results`} rootClassName="table-card" style={{ position: 'relative' }}>

			{!results ?
				<>
					<Flex vertical gap={20} rootClassName="overlay" justify="center" align="center" wrap="wrap" style={{ padding: '40px' }}>
						<Spin size="large" />
						<Text>Please wait while we calculate the results of the competition.</Text>
					</Flex>
					<Table pagination={false}>
						<Column title="Profile" dataIndex="address" key="address" render={(x, row, i) => (
							<Link href={`${config.ScannerUrl}/address/${x}`} target="_blank">
								<Flex align="center" gap={2}>
									<Position index={i} />
									<UserIcon style={{ minWidth: '20px', height: '20px', width: '20px' }} address={x} />
									{row.name ?
										<Text style={{ color: '#FFE814' }}>{row.name}</Text> :
										<Text style={{ color: '#FFE814' }}>{`${x.substr(0, 6)}...${x.substr(38, 4)}`}</Text>
									}
								</Flex>
							</Link>
						)} />
						<Column title="Referral Volume" key="usdVolume" render={(_, row) => (
							<>${formatNumber(row.usdVolume)}</>
						)} />
						<Column title="Prize" key="prize" render={(_, row) => (
							<>{formatNumber(row.prize)} ETH</>
						)} />
					</Table>
				</> :
				<>
					<Flex justify="center" wrap="wrap" style={{ padding: '5px' }}>
						<Flex rootClassName="user-stat" gap={5} align="center">
							<Tooltip title="Start Time">
								<ClockCircleOutlined style={{ fontSize: '16px' }} />
							</Tooltip>
							<Text>{new Date(results.startTime + 'Z').toLocaleDateString()}</Text>
						</Flex>
						<Flex rootClassName="user-stat" gap={5} align="center">
							<Tooltip title="End Time">
								<ClockCircleOutlined style={{ fontSize: '16px' }} />
							</Tooltip>
							<Text>{new Date(results.endTime + 'Z').toLocaleDateString()}</Text>
						</Flex>
						<Flex rootClassName="user-stat" gap={5} align="center">
							<Tooltip title="Prize Pool">
								<DollarOutlined style={{ fontSize: '16px' }} />
							</Tooltip>
							<Text>{parseFloat(results.prizePool ?? 0).toFixed(4)} ETH</Text>
						</Flex>
					</Flex>

					<Table dataSource={results.results} pagination={false}>
						<Column title="Profile" dataIndex="address" key="address" render={(x, row, i) => (
							<Link href={`${config.ScannerUrl}/address/${x}`} target="_blank">
								<Flex align="center" gap={2}>
									<Position index={i} />
									<UserIcon style={{ minWidth: '20px', height: '20px', width: '20px' }} address={x} />
									{row.name ?
										<Text style={{ color: '#FFE814' }}>{row.name}</Text> :
										<Text style={{ color: '#FFE814' }}>{`${x.substr(0, 6)}...${x.substr(38, 4)}`}</Text>
									}
								</Flex>
							</Link>
						)} />
						<Column title="Referral Volume" key="usdVolume" render={(_, row) => (
							<>${formatNumber(row.usdVolume)}</>
						)} />
						<Column title="Prize" key="prize" render={(_, row) => (
							<>{formatNumber(row.prize)} ETH</>
						)} />
					</Table>
					<Pagination pageSize={1} current={competitionIndex + 1} total={latestCompetition} onChange={(page) => { setCompetitionIndex(page - 1); }} />
 				</>
			}
		</Card>
	);
}

const AvailableRewards = ({ config }) => {
	const { address, isConnected, chainId } = useWeb3ModalAccount();
	const { walletProvider } = useWeb3ModalProvider();
	const [balance, setBalance] = React.useState(null);

	React.useEffect(() => {
		const getBalance = async () => {
			const ethersProvider = new BrowserProvider(walletProvider);
			const signer = await ethersProvider.getSigner();
			const apeStore = new Contract(config.ReferCompetition?.PrizeVaultAddress, ApeStorePrizeVaultAbi, signer);
			try {
				const fee = await apeStore.balances(address);
				setBalance(formatEther(fee));
			} catch (ex) {
				console.log(ex)
				setBalance(null);
			}
		}

		if (isConnected) {
			setBalance(null);
			getBalance();
		}
	}, [address, chainId, isConnected, walletProvider, config]);

	const claimRewards = async () => {
		const ethersProvider = new BrowserProvider(walletProvider);
		const signer = await ethersProvider.getSigner();
		const apeStore = new Contract(config.ReferCompetition?.PrizeVaultAddress, ApeStorePrizeVaultAbi, signer);
		const hash = (await apeStore.withdraw()).hash;
		const receipt = await ethersProvider.waitForTransaction(hash);
		openNotification("Prizes Withdrawn", "Success", { border: '1px solid #86EFAC', borderRadius: '18px' });
	}

	const [api, contextHolder] = notification.useNotification();

	const openNotification = (title, message, style) => {
		api.open({
			message: title,
			description: message,
			style: style,
			placement: "bottomRight"
		});
	};

	return (
		<>
			{contextHolder}
			<Card title="Available Rewards" rootClassName="card-vertical">
				<Text rootClassName="card-focal">{`${parseFloat(balance ?? 0).toFixed(4)} ETH`}</Text>
				<Button disabled={parseEther(balance ?? "0") == 0} style={{ width: '100%' }} onClick={claimRewards}>Claim</Button>
			</Card>		
		</>
	);
}

export const Profile = () => {
	const { address, isConnected, chainId } = useWeb3ModalAccount();
	const { walletProvider } = useWeb3ModalProvider();
	const [data, setData] = React.useState(null);
	const [user, setUser] = React.useState(null);
	const [name, setName] = React.useState(null);

	const loadData = async () => {
		setData(null);
		const response = await axios.get(`/api/refer/8453/competition`);
		setData(response.data);
	};
	
	const referralUrl = user === null ? null : `${window.location.origin}/refer/${user.referralCode}`;
	// TODO - not locked to chain
	const config = ApeConfig.find(x => x.ID == 8453); //ApeConfig.find(x => x.Short === tokenChain.toLowerCase() || x.ID == tokenChain);

	const handleChainChange = async (chain) => {
		if (chain !== 0) {
			const provider = new BrowserProvider(walletProvider);
			const chainId = '0x' + (chain).toString(16);
			await provider.send('wallet_switchEthereumChain', [{ chainId }]);
		}
	}

	React.useEffect(() => {
		const loadUser = async () => {
			setUser(null);
			if (address) {
				const response = await axios.get(`/api/user/${address}`);
				setUser(response.data);
				setName(response.data.user?.name);
			}
		};

		if (isConnected && address) {
			loadUser();
		}
	}, [address, isConnected])

	React.useEffect(() => {
		loadData();
	}, [])

	return (
		<>
			<div style={{ zIndex: 1, position: "relative", background: 'rgb(27, 29, 40)', marginTop: '-64px', minHeight: '100vh', width: '100%'  }}>
				<div style={{ width: '100%', position: 'relative', minHeight: '100vh', background: '#1B1D28' }}>
				<div style={{
					background: 'url("/img/profile-bg.png")',
					backgroundSize: '200vw 80vw',
					backgroundRepeat: 'no-repeat',
					width: '100%',
					minHeight: '100vh',
					angle: '-180 deg',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'flex-start',
					alignItems: 'flex-start'
				}}>
					
					<div style={{
							position: 'absolute', width: '100%', height: '2000px', padding: '30px', top: 0, background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) -200%, #1B1D28 25%)'}}  />
						{ !data ?
							<Spin /> :
							<div style={{ width: '100%', padding: '30px', paddingTop: '96px' }}>
								{!isConnected ? <Flex vertical justify="center" align="center" rootClassName="overlay">
									<Text style={{ marginBottom: '30px' }}>Please connect your Web3 wallet to edit your profile.</Text>
									<ConnectButton />
								</Flex> :
									8453 !== chainId ? <Flex justify="center" align="center" vertical rootClassName="overlay">
										<Text style={{ marginBottom: '30px' }}>You are connected to the wrong chain.</Text>
										<Button onClick={() => { handleChainChange(config.ID); }}>{`Switch to ${config.Name}`}</Button>
									</Flex> : <></>}
								
								<Row>
									<Col xs={24} lg={14} style={{ padding: '10px' }}>
										<Text rootClassName="refer-page-header">My Profile</Text>
										<Row>
											<Col xs={24} lg={16}>
												<Flex>
													<Avatar style={{ width: '64px', height: '64px' }} icon={<BlockiesSvg scale={8} size={9} address={address ?? "".toLowerCase()} />}>{address ?? "".toLowerCase()}</Avatar>
													<Flex vertical rootClassName="user-stats trans">
														<NameDisplay name={name} setName={setName} address={address} />
														<Stats data={user} />
													</Flex>
												</Flex>
											</Col>
											<Col xs={0} lg={8}>
												<AvailableRewards config={config} />
											</Col>
										</Row>
										<Flex vertical gap={10} style={{ marginTop: '20px', marginBottom: '20px' }}>
											<Divider style={{ margin: '0', paddingBottom: '10px' }} />
											<Text>Referral Link</Text>
											<Text rootClassName="referral-link" copyable={{ text: referralUrl }}>{referralUrl}</Text>
										</Flex>
										<Row>
											<Col xs={24} lg={0}>
												<AvailableRewards config={config} />
											</Col>
											<Col xs={0} lg={24}>
												<CompetitionHistory latestCompetition={data.index} />
											</Col>
										</Row>
									</Col>
									<Col xs={24} lg={10} style={{ padding: '10px' }}>
										<Flex vertical gap={20}>
											<Flex gap={10}>
												<Card title="Leaderboard Reset ⏱️" rootClassName="card-vertical" style={{ width: '50%' }}>
													<Countdown rootClassName="card-focal countdown" value={data.endTime} onFinish={loadData}  />
												</Card>
												<Card title="Prize Pool 🎁" rootClassName="card-vertical" style={{ width: '50%' }}>
													{data.competitionReady && data.prizePool.toFixed(4) > 0 ?
														<Statistic rootClassName="card-focal" value={data.prizePool.toFixed(4)} suffix=" ETH" /> :
														<Statistic rootClassName="card-focal" value="TBA"/>
													}
												</Card>
											</Flex>
											<Leaderboard leaderboard={data.leaderboard} />
										</Flex>
									</Col>
									<Col xs={24} lg={0} style={{ padding: '10px' }}>
										<CompetitionHistory latestCompetition={data.index} />
									</Col>
								</Row>
							</div>
						}
						</div>
					
				</div>
			</div>	
		</>
	);
};
