/*global ApeConfig*/

import React from 'react';
import axios from "axios";
import { formatEther, parseEther } from 'ethers/utils';
import Moment from 'react-moment';
import { EditFilled, EyeInvisibleFilled, EyeFilled, FileImageFilled, FileTextFilled, BellFilled, DeleteFilled, UploadOutlined, CloseCircleFilled, ExportOutlined } from '@ant-design/icons';
import { Typography, Col, Row, Flex, Tooltip, Input, Modal, Spin, Image, Button, Slider, Card, Tabs, Table, List, Upload } from 'antd';
import { useWeb3ModalProvider, useWeb3ModalAccount } from '@web3modal/ethers/react'
import { BrowserProvider, Contract } from 'ethers'
import { SocialIcon } from './shared/SocialIcon';
import { TwitterIcon, TelegramIcon, WebIcon, SwapIcon, SettingsIcon, BananaIcon, TooltipIcon, LinkIcon, ReceiptIcon, PenIcon, ChartIcon, TxIcon, MetamaskIcon } from './shared/ApeIcons';
import { ApeStoreContractAbiV1, ApeStoreContractAbiV2, ApeStoreProxyAbi, IERC20 } from './shared/ApeStoreContract';
import { useParams } from "react-router-dom";
import { notification } from 'antd';
import { TokenLogo } from './shared/TokenLogo';
import { ChainDisplay } from './shared/ChainSelect';
import { Chart, DexScreenerChart } from './shared/Chart';
import { TokenInput } from './shared/TokenInput';
import './shared/custom.css';
import { useSockets } from './context/SocketsContext';
import { UserIcon } from './shared/UserIcon';
import { ConnectButton } from './shared/ConnectButton';
import { usePermissions } from './context/PermissionsContext';
import { useLocalState } from '../App';
import { getWalletClient } from '../datafeed/Paymaster';
import { base } from 'viem/chains'

const { Column } = Table;
const { Link, Text } = Typography;
const { TextArea } = Input;

export const useDebouncedValue = (inputValue, delay) => {
	const [debouncedValue, setDebouncedValue] = React.useState(inputValue);

	React.useEffect(() => {
		const handler = setTimeout(() => {
			setDebouncedValue(inputValue);
		}, delay);

		return () => {
			clearTimeout(handler);
		};
	}, [inputValue, delay]);

	return debouncedValue;
};

export const ViewToken = () => {
	const { tokenAddress, tokenChain } = useParams();
	const { mobile, latestEvent, refreshRequired, setRefreshRequired, latestKey, subscribeToken, unsubscribeToken } = useSockets();
	const { address, chainId, isConnected } = useWeb3ModalAccount();
	const { walletProvider } = useWeb3ModalProvider();
	const [api, contextHolder] = notification.useNotification();
	const { isAdmin, sessionId, isAuthenticated, signature } = usePermissions();

	const [data, setData] = React.useState(null);
	const [marketCap, setMarketcap] = React.useState(null);

	const [isBuy, setIsBuy] = React.useState(true);
	const [amountIn, setAmountIn] = React.useState("0");
	const [amountOut, setAmountOut] = React.useState("0");
	const [tokenBalance, setTokenBalance] = React.useState("0");
	const [nativeBalance, setNativeBalance] = React.useState("0");
	const [slippage, setSlippage] = useLocalState(2, "slippage")
	const [canSubmit, setCanSubmit] = React.useState(false);
	const [isModalOpen, setIsModalOpen] = React.useState(false);
	const [message, setMessage] = React.useState("");
	const [hasMap, setHasMap] = React.useState(false);
	const [approved, setApproved] = React.useState(0);
	const [swapping, setSwapping] = React.useState(false);

	const [showEditSocials, setShowEditSocials] = React.useState(false);
	const [twitter, setTwitter] = React.useState("");
	const [telegram, setTelegram] = React.useState("");
	const [website, setWebsite] = React.useState("");

	const [showEditDescription, setShowEditDescription] = React.useState(false);
	const [description, setDescription] = React.useState("");
	const [showEditLogo, setShowEditLogo] = React.useState(false);
	const [fileList, setFileList] = React.useState([]);
	const [user, setUser] = React.useState(null);
	const debouncedAmountIn = useDebouncedValue(amountIn, 500);
	const config = ApeConfig.find(x => x.Short === tokenChain.toLowerCase() || x.ID == tokenChain);
	const native = { address: config.Wrapped, name: config.NativeToken, symbol: config.NativeToken, decimals: config.NativeDecimals, logo: config.NativeLogo };
	const router = config.ApeRouters[data?.token?.router];

	const getRouterVersion = () => {
		return data?.token?.router >= config.RouterVersion2 ? 2 : 1;
	}

	const getRouterAbi = () => {
		return data?.token?.router >= config.RouterVersion2 ? ApeStoreContractAbiV2 : ApeStoreContractAbiV1;
	}

	const loadData = async () => {
		try {
			const response = await axios.get(`/api/token/${tokenChain}/${tokenAddress}`);
			setData(response.data);
			setMarketcap(response.data.marketCap)
			setHasMap(response.data.hasMap);
		}
		catch (err) {
			console.error(err);
			setData(null);
		}
	};

	React.useEffect(() => {
		try {
			if (latestEvent && latestEvent.transaction && latestEvent.token?.id === data?.token?.id) {
				setMarketcap(latestEvent.token.marketCap);
			}
		}
		catch (ex) {
			console.error(ex);
		}
	}, [latestEvent, data]);

	React.useEffect(() => {
		const loadUser = async () => {
			setUser(null);
			if (address) {
				const response = await axios.get(`/api/user/${address}`);
				setUser(response.data);
			}
		};

		if (isConnected && address) {
			loadUser();
		}
	}, [address, isConnected])

	React.useEffect(() => {
		if (refreshRequired) {
			setData(null);
			loadData();
			setRefreshRequired(false);
		}
	
	}, [refreshRequired]);

	React.useEffect(() => {
		subscribeToken(tokenChain, tokenAddress);
		return () => unsubscribeToken(tokenChain, tokenAddress);
	}, []);

	React.useEffect(() => {
		subscribeToken(tokenChain, tokenAddress);
		setData(null);
		loadData();
		return () => unsubscribeToken(tokenChain, tokenAddress);
	}, [tokenAddress, tokenChain]);

	React.useEffect(() => {
		setTwitter(data?.token.twitter);
		setTelegram(data?.token.telegram);
		setWebsite(data?.token.website);
		setDescription(data?.token.description);
	}, [data]);

	const validateSwapForm = async () => {
		if (data?.token.launchDate != null) {
			let isAllowed = true;
			if (!isBuy) {
				isAllowed = approved >= amountIn;
			}
			setCanSubmit(isAllowed && !swapping && isConnected && amountIn > 0 && amountOut > 0 && chainId === ApeConfig.find(x => x.Short === tokenChain || x.ID == tokenChain).ID);
		} else {
			setCanSubmit(!swapping && isConnected && amountIn > 0 && amountOut > 0 && chainId === ApeConfig.find(x => x.Short === tokenChain || x.ID == tokenChain).ID);
		}
	}

	const loadBalances = async () => {
		if (isConnected && address && chainId === config.ID) {
			const ethersProvider = new BrowserProvider(walletProvider);
			const signer = await ethersProvider.getSigner();
			const apeStore = new Contract(tokenAddress, IERC20, signer);

			setTokenBalance(formatEther((await apeStore.balanceOf(address)).toString()));
			setNativeBalance(formatEther((await ethersProvider.getBalance(address)).toString()));
			setApproved(formatEther((await apeStore.allowance(address, config.ApeProxy)).toString()));
		} else {
			setNativeBalance(0);
			setTokenBalance(0);
			setApproved(0);
		}
	};

	if (tokenAddress && tokenChain) {
		subscribeToken(tokenChain, tokenAddress);
	}

	React.useEffect(() => {
		loadBalances();
	}, [address, chainId, isConnected, data]);

	React.useEffect(() => {
		validateSwapForm();
	}, [isConnected, amountIn, amountOut, chainId, isBuy, data, approved, swapping]);

	React.useEffect(() => {
		const getQuote = async () => {
			try {
				if (amountIn > 0) {
					const ethersProvider = new BrowserProvider(walletProvider);
					const signer = await ethersProvider.getSigner();
					if (data.token.launchDate != null) {
						const apeStore = new Contract(config.ApeProxy, ApeStoreProxyAbi, signer);
						if (isBuy) {
							setAmountOut(formatEther((await apeStore.buyQuote(tokenAddress, parseEther(amountIn))).toString()));
						} else {
							setAmountOut(formatEther((await apeStore.sellQuote(tokenAddress, parseEther(amountIn))).toString()));
						}
					} else {
						const apeStore = new Contract(router, getRouterAbi(), signer);
						if (isBuy) {
							setAmountOut(formatEther((await apeStore.buyQuote(tokenAddress, parseEther(amountIn))).toString()));
						} else {
							setAmountOut(formatEther((await apeStore.sellQuote(tokenAddress, parseEther(amountIn))).toString()));
						}
					}
				} else {
					setAmountOut("0")
				}
			}
			catch (err) {
				console.error(err);
				setAmountOut(0);
			}
		};

		getQuote();

		const intervalCall = setInterval(() => {
			setAmountOut("0");
			getQuote();
		}, 15000);

		return () => {
			clearInterval(intervalCall);
		};
	}, [tokenAddress, chainId, debouncedAmountIn, isBuy]);

	const handleCancel = () => {
		setIsModalOpen(false);
	};

	const handleSwap = async () => {
		if (data.token.launchDate != null) {
			try {
				setSwapping(true);
				const ethersProvider = new BrowserProvider(walletProvider);
				const signer = await ethersProvider.getSigner();
				const apeStore = new Contract(config.ApeProxy, ApeStoreProxyAbi, signer);
				const minAmount = parseEther((amountOut * (100 - slippage) / 100).toFixed(18).toString());
				let hash = "";
				if (isBuy) {
					hash = (await apeStore.buy(minAmount, [config.Wrapped, tokenAddress], address, Math.round(Date.now() / 1000 + 60).toString(), { value: parseEther(amountIn) })).hash;
				} else {
					hash = (await apeStore.sell(parseEther(amountIn), minAmount, [tokenAddress, config.Wrapped], address, Math.round(Date.now() / 1000 + 60000).toString())).hash;
				}
				await ethersProvider.waitForTransaction(hash, 1);
				openNotification("Swap Confirmed", "", { border: 'solid 1px #86EFAC', borderRadius: '18px' });
				setData(null);
				loadData();
				loadBalances();
			} catch (err) {
				console.error(err);
				openNotification("Swap Failed", err.message, { border: 'solid 1px red', borderRadius: '18px' });
			} finally {
				setSwapping(false);
			}
		} else {
			setIsModalOpen(true);
		}
	}

	const checkBatchTransaction = async (walletClient, hash) => {
		while (true) {
			const { status, receipts } = await walletClient.getCallsStatus({
				id: hash,
			});

			if (status !== "PENDING") {
				return receipts[0].transactionHash;
			}
		}
	}

	const handleConfirm = async () => {
		try {
			setSwapping(true);
			setIsModalOpen(false);
			const key = await latestKey();
			const ethersProvider = new BrowserProvider(walletProvider);
			const signer = await ethersProvider.getSigner();
			const apeStore = new Contract(router, getRouterAbi(), signer);	
			const signature = (await axios.post("/api/transaction", { tokenId: data?.token.id, address, key, amount: parseEther(amountIn).toString() })).data;
			let isPaymaster = false;
			let hash = "";
			if (isBuy) {
				switch (getRouterVersion()) {
					case 1:
						hash = (await apeStore.buy(tokenAddress, parseEther(amountIn), { value: parseEther(amountIn) })).hash;
						break;
					default:
						const minAmount = parseEther((amountOut * (100 - slippage) / 100).toFixed(18).toString());
						if (walletProvider.isCoinbaseWallet && config.PaymasterUrl) {
							try {
								const walletClient = getWalletClient(walletProvider);
								const capabilities = await walletClient.getCapabilities({ account: address });
								if (capabilities[8453] && capabilities[8453]["paymasterService"] && capabilities[8453]["paymasterService"].supported) {
									hash = await walletClient.writeContracts({
										account: address,
										chain: base,
										contracts: [{
											abi: getRouterAbi(),
											address: router,
											functionName: 'buy',
											args: [tokenAddress, parseEther(amountIn), minAmount, signature],
											value: parseEther(amountIn)
										}],
										capabilities: {
											paymasterService: {
												url: config.PaymasterUrl
											}
										}
									});
									isPaymaster = true;
									hash = await checkBatchTransaction(walletClient, hash);
								} else {
									hash = (await apeStore.buy(tokenAddress, parseEther(amountIn), minAmount, signature, { value: parseEther(amountIn) })).hash;
								}
							} catch (error) {
								hash = (await apeStore.buy(tokenAddress, parseEther(amountIn), minAmount, signature, { value: parseEther(amountIn) })).hash;
							}
						} else {						
							hash = (await apeStore.buy(tokenAddress, parseEther(amountIn), minAmount, signature, { value: parseEther(amountIn) })).hash;
						}
						break;
				}
			} else {
				const minAmount = parseEther((amountOut * (100 - slippage) / 100).toFixed(18).toString());
				switch (getRouterVersion()) {
					case 1:
						hash = (await apeStore.sell(tokenAddress, parseEther(amountIn), minAmount)).hash;
						break;
					default:
						if (walletProvider.isCoinbaseWallet && config.PaymasterUrl) {
							try {
								const walletClient = getWalletClient(walletProvider);
								const capabilities = await walletClient.getCapabilities({ account: address });
								if (capabilities[8453] && capabilities[8453]["paymasterService"] && capabilities[8453]["paymasterService"].supported) {
									hash = await walletClient.writeContracts({
										account: address,
										chain: base,
										contracts: [{
											abi: getRouterAbi(),
											address: router,
											functionName: 'sell',
											args: [tokenAddress, parseEther(amountIn), minAmount, signature]
										}],
										capabilities: {
											paymasterService: {
												url: config.PaymasterUrl
											}
										}
									});
									isPaymaster = true;
									hash = await checkBatchTransaction(walletClient, hash);
								} else {
									hash = (await apeStore.sell(tokenAddress, parseEther(amountIn), minAmount, signature)).hash;
								}
							}
							catch (error) {
								hash = (await apeStore.sell(tokenAddress, parseEther(amountIn), minAmount, signature)).hash;
							}
						} else {
							hash = (await apeStore.sell(tokenAddress, parseEther(amountIn), minAmount, signature)).hash;
						}
						break;
				}
			}

			await axios.post("/api/message", { transactionHash: hash, message })
			if (isPaymaster === false) {
				await ethersProvider.waitForTransaction(hash, 1);
			}
			
			openNotification("Swap Confirmed", "", { border: 'solid 1px #86EFAC', borderRadius: '18px' });
			setData(null);
			loadData();
			loadBalances();
		}
		catch (err) {
			console.error(err);
			openNotification("Swap Failed", err.message, { border: 'solid 1px red', borderRadius: '18px' });
		}
		finally {
			setSwapping(false);
		}
	}

	const handleEditSocials = async () => {
		try {
			const ethersProvider = new BrowserProvider(walletProvider);
			const signer = await ethersProvider.getSigner();
			const signature = await signer.signMessage(`${data.token.id}|${twitter}|${telegram}|${website}|${sessionId}`);
			const response = await axios.post(`/api/${(isAdmin && isAuthenticated ? 'admin/' : '')}editsocials/${data.token.id}`, {
				twitter,
				telegram,
				website,
				signature
			});
			setShowEditSocials(false);
			loadData();
		}
		catch (err) {
			console.error(err);
		}
	}

	const handleEditDescription = async () => {
		try {
			const ethersProvider = new BrowserProvider(walletProvider);
			const signer = await ethersProvider.getSigner();
			const signature = await signer.signMessage(`${data.token.id}|desc|${description}|${sessionId}`);
			const response = await axios.post(`/api/admin/desc/${data.token.id}`, {
				description,
				signature
			});
			setShowEditDescription(false);
			loadData();
		}
		catch (err) {
			console.error(err);
		}
	}

	const handleEditLogo = async () => {
		try {
			const ethersProvider = new BrowserProvider(walletProvider);
			const signer = await ethersProvider.getSigner();
			const signature = await signer.signMessage(`${data.token.id}|logo|${sessionId}`);

			const formData = new FormData();
			fileList.forEach((file) => {
				formData.append('files[]', file);
			});
			formData.append('data.signature', signature);

			const postResponse = await axios.postForm(`/api/admin/logo/${data.token.id}`, formData);

			setShowEditLogo(false);
			loadData();
		}
		catch (err) {
			console.error(err);
		}
	}

	const handleToggleShow = async () => {
		try {
			const hide = !data.token.hidden;
			const ethersProvider = new BrowserProvider(walletProvider);
			const signer = await ethersProvider.getSigner();
			const signature = await signer.signMessage(`${data.token.id}|${hide ? "hide" : "show"}|${sessionId}`);
			const response = await axios.post(`/api/admin/hide/${data.token.id}`, {
				hide,
				signature
			});
			loadData();
		}
		catch (err) {
			console.error(err);
		}
	}

	const beforeUpload = (file) => {
		/*const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
		if (!isJpgOrPng) {
			message.error('You can only upload JPG/PNG file!');
		}*/
		const isOkSize = file.size / 1024 <= 512;
		if (!isOkSize) {
			message.error('Image must smaller than 512kb!');
		}
		return isOkSize;
	};

	const openNotification = (title, message, style) => {
		api.open({
			message: title,
			description: message,
			style: style,
			placement: "bottomRight"
		});
	};

	const Info = () => {
		return (
			<Flex vertical>
				<Flex gap={5} justify="flex-end">
					<ChainDisplay chain={data?.token.chain} />
					<Text style={{ fontSize: '12px' }} copyable={{ text: data.token.address }} >{`${data.token.address.substr(0, 6)}...${data.token.address.substr(38)}`}</Text></Flex>
				<Flex gap={10}>
					<TokenLogo value={data.token} size={128} />
					<Flex vertical gap={5} style={{ width: '100%'}}>
						<Flex align="center" justify="space-between">
							<Text style={{
								fontSize: '14px',
								fontWeight: 700,
								color: '#fff',
								lineHeight: '20px',
							}}>{data.token.name}</Text>
							<Socials token={data?.token} setShowEditSocials={setShowEditSocials} />
							{user != null && user.user?.registered ? <Text style={{ fontSize: '12px' }}
								copyable={{
									text: `${window.location.origin}/refer/${user.referralCode}/${data.token.chain}/${data.token.address}`,
									icon: [<ExportOutlined key="copy-icon" />, <ExportOutlined key="copied-icon" />],
									tooltips: ['Share this page with your referral link', 'Copied'],
								}} ></Text> : <></>}	
						</Flex>
						<Flex align="center" justify="space-between">
							<Flex>
								<Text style={{
									fontSize: '14px',
									fontWeight: 700,
									color: '#9CA3AF',
									lineHeight: '20px',
								}}>({data.token.symbol})</Text>
								<UserIcon address={data?.token.creator} style={{ width: '18px', height: '18px', marginLeft: '10px' }} />
							</Flex>
						</Flex>
						<Text style={{
							paddingTop: '10px',
							fontSize: '12px',
							fontWeight: 400,
							color: '#9CA3AF',
							lineHeight: '16px'
						}}>{data.token.description}</Text>
					</Flex>
				</Flex>
				{isAdmin && isAuthenticated ?
					<Flex rootClassName="admin-tools" justify="space-between" align="center">
						<Text>Admin</Text>
						<Text>{`Router: ${data?.token.router}`}</Text>
						<Flex gap={5}>
							<Tooltip title={data?.token.hidden ? "Show" : "Hide"}><Button type="primary" onClick={handleToggleShow} shape="circle" icon={data?.token.hidden ? <EyeFilled /> : <EyeInvisibleFilled />} size={24} /></Tooltip>
							<Tooltip title="Edit Logo"><Button type="primary" onClick={() => { setShowEditLogo(true); }} shape="circle" icon={<FileImageFilled />} size={24} /></Tooltip>
							<Tooltip title="Edit Description"><Button type="primary" onClick={() => { setShowEditDescription(true); }} shape="circle" icon={<FileTextFilled />} size={24} /></Tooltip>
							<Tooltip title="Edit Socials"><Button type="primary" onClick={() => { setShowEditSocials(true); }} shape="circle" icon={<BellFilled />} size={24} /></Tooltip>
						</Flex>
					</Flex> :
					<></>
				}
			</Flex>
		);
	};

	const Ape = () => {
		return (
			<Flex wrap="wrap" justify="flex-end" gap={10} style={{ width: '100%', maxWidth: '800px'}}>
				<Flex vertical style={{ width: '100%', minWidth: '325px', maxWidth: '500px' }}>
					<Flex align="center" gap={5}>
						<Text>Free the Ape Progress: <Text style={{ color: '#4D8963' }}>{data.token.launchDate ? 100 : Math.round(Math.min(100, marketCap / 69_000 * 100))}%</Text></Text>
						<Tooltip title={`When the curve hits 100%, the token will have reached a market cap of $69K and will be listed on ${config.DexName}.`}>
							<div>
								<TooltipIcon size={12} />
							</div>
						</Tooltip>
					</Flex>
					<Slider value={data.token.launchDate ? 100 : Math.min(100, marketCap / 69_000 * 100)} styles={{
						track: {
							background: '#4D8963'
						},
						handle: {
							boxShadow: '0 0 0 1px #4D8963',
							background: 'url(/img/logo.png)',
							backgroundColor: '#267656'
						}
					}} />
					{data.token.launchDate ? <Text style={{ fontSize: '10px'}}>{`Went free on ${new Date(data.token.launchDate + 'Z')}`}</Text> : <></> }
				</Flex>
				<Flex vertical style={{ width: '100%', minWidth: '325px', maxWidth: '500px' }}>
					<Flex align="center" gap={5}>
						<Text>King of Apes progress: <Text style={{ color: '#EAB308' }}>{data.token.kingDate ? 100 : Math.round(Math.min(100, marketCap / 34_500 * 100))}%</Text></Text>
						<Tooltip title='When the curve reaches 100%, the token will have reached a market cap of $34.5K and will replace the current "King of Apes" on the podium.'>
							<div>
								<TooltipIcon size={12} />
							</div>
						</Tooltip>
					</Flex>
					<Slider value={data.token.kingDate ? 100 : Math.min(100, marketCap / 34_500 * 100)} size="large" styles={{
						track: {
							background: '#EAB308'
						},
						handle: {
							boxShadow: '0 0 0 1px #EAB308',
							background: 'url(/img/crown.png)',
							backgroundColor: '#6B652A'
						}
					}} />
					{data.token.kingDate ? <Text style={{ fontSize: '10px' }}>{`Crowned King on ${new Date(data.token.kingDate + 'Z')}`}</Text> : <></>}
				</Flex>
			</Flex>
		);
	};

	const mobileTabs = [
		{
			key: '1',
			label: <Flex vertical justify="center" align="center" gap={10}>
				<ReceiptIcon size={24} />
				<div>Info</div>
			</Flex>,
			children: <Flex vertical gap={10}>
				<Info />
				<Flex gap={20} justify="space-between" style={{ paddingBottom: '8px'}}>
					<Text style={{ fontSize: '12px' }}>{`Market cap: $${marketCap}`}</Text>
					{data?.token.launchDate != null ? <Text style={{ fontSize: '12px' }}>{`Liquidity: $${data?.liquidity}`}</Text> : <></>}
					{data?.token.launchDate == null ? <Text style={{ fontSize: '12px' }}>{`Virtual liquidity: $${data?.virtualLiquidity}`}</Text> : <></>}
				</Flex>
				<Ape />
				<Holders token={data?.token} config={config} mobile={mobile} />
			</Flex>,
		},
		{
			key: '2',
			label: <Flex vertical justify="center" align="center" gap={10}>
				<ChartIcon size={24} />
				<div>Chart</div>
			</Flex>,
			children: <ChartContainer config={config} token={data?.token} hasMap={hasMap} />,
		},
		{
			key: '3',
			label: <Flex vertical justify="center" align="center" gap={10}>
				<SwapIcon size={24} />
				<div>Buy / Sell</div>
			</Flex>,
			children: <Flex vertical gap={10}>
				<Swap config={config} approved={approved} setApproved={setApproved} token={data?.token} native={native} amountIn={amountIn} setAmountIn={setAmountIn} nativeBalance={nativeBalance}
					tokenBalance={tokenBalance} isBuy={isBuy} setIsBuy={setIsBuy} amountOut={amountOut} setAmountOut={setAmountOut} slippage={slippage} setSlippage={setSlippage}
					canSubmit={canSubmit} handleSwap={handleSwap} />
				<Chat token={data?.token} config={config} mobile={mobile} />
			</Flex>,
		},
		{
			key: '4',
			label: <Flex vertical justify="center" align="center" gap={10}>
				<TxIcon size={24} />
				<div>TXs</div>
			</Flex>,
			children: <Trades token={data?.token} config={config} mobile={mobile} />,
		},
	];

	const tabs = [
		{
			key: '1',
			label: 'Live Chat',
			children: <Chat token={data?.token} config={config} />,
		},
		{
			key: '2',
			label: 'Trades',
			children: <Trades token={data?.token} config={config} />,
		},
	];

	return (
		!data || !data.token ? <Spin fullscreen size="large" style={{ margin: 'auto'}} /> : (
			<>
				{contextHolder}
				<Row style={{ padding: '20px' }}>
					<Col xs={24} lg={0} style={{ marginBottom: '100px'}}>
						<Tabs rootClassName="view-mobile-tabs" defaultActiveKey="1" tabPosition="bottom" items={mobileTabs} moreIcon={null} indicator={{size: 0}} />
					</Col>

					<Col xs={0} lg={24}>
						<Flex justify="space-between">
							<Flex vertical gap={10} justify="space-between" style={{ minWidth: '300px'}}>
								<Flex align="center" gap={10}>
									{user != null && user.user?.registered ? <Text style={{ fontSize: '12px' }}
										copyable={{
											text: `${window.location.origin}/refer/${user.referralCode}/${data.token.chain}/${data.token.address}`,
											icon: [<ExportOutlined key="copy-icon" />, <ExportOutlined key="copied-icon" />],
											tooltips: ['Share this page with your referral link', 'Copied'],
										}} ></Text> : <></>}			
								</Flex>
								<Flex align="center" gap={10}>
									<Text style={{
										fontSize: '28px',
										fontWeight: 500,
										lineHeight: '28px',
									}}>{data.token.symbol}</Text>
									
									<Socials token={data.token} setShowEditSocials={setShowEditSocials} />
								</Flex>
								<Flex>
									<Text style={{
										fontSize: '28px',
										fontWeight: 500,
										lineHeight: '28px',
									}}>${(marketCap / 1000000000).toFixed(7)}</Text>
								</Flex>
								<Flex align="center" gap={5}>
									<UserIcon address={data?.token.creator} style={{ width: '18px', height: '18px', marginRight: '10px'}} />
									<ChainDisplay chain={data.token.chain} />
									<Text style={{ fontSize: '12px' }} copyable={{ text: data.token.address }} >{`${data.token.address.substr(0, 6)}...${data.token.address.substr(38)}`}</Text>
								</Flex>
								<Flex gap={20}>
									<Text style={{ fontSize: '12px' }}>{`Market cap: $${marketCap}`}</Text>
									{data.token.launchDate != null ? <Text style={{ fontSize: '12px' }}>{`Liquidity: $${data.liquidity}`}</Text> : <></> }
									{data.token.launchDate == null ? <Text style={{ fontSize: '12px' }}>{`Virtual liquidity: $${data.virtualLiquidity}`}</Text> : <></>}
								</Flex>
							</Flex>
							<Flex vertical gap={10} justify="flex-start">
								<Flex align="center" gap={10}>
									<TokenLogo value={data.token} size={36} />
									<Text style={{
										fontSize: '20px',
										fontWeight: 400,
										color: '#fff',
										lineHeight: '20px',
									}}>{data.token.name}</Text>
								</Flex>
								<Text style={{
									paddingTop: '10px',
									fontSize: '12px',
									fontWeight: 400,
									color: '#9CA3AF',
									lineHeight: '16px',
									maxHeight: '250px',
									overflow: 'hidden'
								}}>{data.token.description}</Text>
							</Flex>
							<Ape />
						</Flex>
					</Col>
				</Row>
				<Row style={{ padding: '20px' }}>
					<Col xs={0} lg={16} style={{ paddingRight: '5px'}}>
						<Flex vertical gap={10}>
							<ChartContainer config={config} token={data.token} hasMap={hasMap} />
							<Tabs defaultActiveKey="1" items={tabs} />
						</Flex>
					</Col>
					<Col xs={0} lg={8} style={{ paddingLeft: '5px' }}>
						<Flex vertical gap={10}>
							<Swap config={config} approved={approved} setApproved={setApproved} token={data?.token} native={native} amountIn={amountIn} setAmountIn={setAmountIn} nativeBalance={nativeBalance}
								tokenBalance={tokenBalance} isBuy={isBuy} setIsBuy={setIsBuy} amountOut={amountOut} setAmountOut={setAmountOut} slippage={slippage} setSlippage={setSlippage}
								canSubmit={canSubmit} handleSwap={handleSwap} />
							<TradeChat token={data.token} config={config} native={native} />
							<Info />
							<Holders token={data.token} config={config} mobile={mobile} />
						</Flex>
					</Col>
				</Row>
				<Modal title="Add a comment" open={isModalOpen} onOk={handleConfirm} onCancel={handleCancel} closable={false} okButtonProps={{ type: "default" }}>
					<TextArea rows={3} placeholder="Optional" value={message} onChange={(e) => setMessage(e.target.value)} />
				</Modal>
				<Modal title="Edit Socials" open={showEditSocials} onOk={handleEditSocials} onCancel={() => { setShowEditSocials(false); } } closable={false} okButtonProps={{ type: "default" }}>
					<Flex style={{ padding: 0, marginTop: '10px' }} gap={5} vertical>
						<Text>Telegram Link</Text>
						<Input placeholder="Optional" value={telegram} onChange={(e) => setTelegram(e.target.value)} />
						<Text>Twitter Link</Text>
						<Input placeholder="Optional" value={twitter} onChange={(e) => setTwitter(e.target.value)} />
						<Text>Website</Text>
						<Input placeholder="Optional" value={website} onChange={(e) => setWebsite(e.target.value)} />
					</Flex>
				</Modal>
				<Modal title="Edit Description" open={showEditDescription} onOk={handleEditDescription} onCancel={() => { setShowEditDescription(false); }} closable={false} okButtonProps={{ type: "default" }}>
					<Flex style={{ padding: 0, marginTop: '10px' }} gap={5} vertical>
						<Text>Description</Text>
						<TextArea rows={3} value={description} onChange={(e) => setDescription(e.target.value)} />
					</Flex>
				</Modal>
				<Modal title="Edit Logo" open={showEditLogo} onOk={handleEditLogo} onCancel={() => { setShowEditLogo(false); }} closable={false} okButtonProps={{ type: "default" }}>
					<Flex style={{ padding: 0, marginTop: '10px' }} gap={5} vertical>
						<Text>Upload a new logo, or leave blank to clear the current.</Text>
						<Upload
							style={{
								width: '100%',
								height: '100%'
							}}
							onRemove={(file) => {
								setFileList([]);
							}}
							beforeUpload={(file) => {
								if (beforeUpload(file)) {
									setFileList([file]);
									return true;
								} else {
									return false;
								}
							}}
							fileList={fileList}
						>
							<Button style={{
								width: '100%'
							}} icon={<UploadOutlined />}>Select File</Button>
						</Upload>
					</Flex>
				</Modal>
			</>
		)
	)
}

const Socials = ({ token, setShowEditSocials }) => {
	const { address } = useWeb3ModalAccount();

	return (
		<Flex justify="flex-start" align="center">
			{token.website ? <SocialIcon icon={WebIcon} href={token.website} size={15} style={{ margin: "5px" }} /> : <></>}
			{token.telegram ? <SocialIcon icon={TelegramIcon} href={token.telegram} size={15} style={{ margin: "5px" }} /> : <></>}
			{token.twitter ? <SocialIcon icon={TwitterIcon} href={token.twitter} size={15} style={{ margin: "5px" }} /> : <></>}
			{token.launchDate === null && (address ?? "").toLowerCase() === (token.creator ?? "").toLowerCase() ? <SocialIcon icon={EditFilled} onClick={() => { setShowEditSocials(true) }} size={15} style={{ margin: "5px" }} /> : <></>}
		</Flex>
	);
}

const ChartContainer = ({ config, token, hasMap }) => {

	const tabs = [
		{
			key: 'bonding-curve',
			label: 'Bonding Curve',
			children: <Chart symbol={token.address} style={{ height: '350px' }} />,
		}
	];

	if (token.launchDate != null) {
		tabs.unshift({
			key: 'dex',
			label: config.DexName,
			children: <DexScreenerChart token={token} style={{ height: '350px' }} />,
		});
	}

	if (hasMap) {
		tabs.push({
			key: 'map',
			label: "Bubble Map",
			children: <iframe title={`Bubble Map`} src={`https://app.bubblemaps.io/base/token/${token.address}`} style={{ border: 'none', height: '350px', width: '100%' }} />,
		});
	}

	return (<Tabs items={tabs} />)
};

const Swap = ({ config, approved, setApproved, token, native, amountIn, setAmountIn, nativeBalance, tokenBalance, isBuy, setIsBuy, amountOut, setAmountOut, slippage, setSlippage, canSubmit, handleSwap}) => {
	const { chainId, isConnected } = useWeb3ModalAccount();
	const { walletProvider } = useWeb3ModalProvider();
	const [canApprove, setCanApprove] = React.useState(true);
	const [showSlippage, setShowSlippage] = React.useState(false);
	const showApprove = isBuy === false && token.launchDate !== null && amountIn > approved;

	const handleApprove = async () => {
		try {
			setCanApprove(false);
			const ethersProvider = new BrowserProvider(walletProvider);
			const signer = await ethersProvider.getSigner();
			const apeStore = new Contract(token.address, IERC20, signer);
			const hash = (await apeStore.approve(config.ApeProxy, parseEther(amountIn.toString()))).hash;
			await ethersProvider.waitForTransaction(hash, 1);
			setApproved(amountIn);
		}
		catch (err) {
			console.error(err);
		}
		finally {
			setCanApprove(true);
		}
	}

	const handleEditSlippage = (value) => {
		setSlippage(value);
	}

	const handleChainChange = async (chain) => {
		if (chain !== 0) {
			const provider = new BrowserProvider(walletProvider);
			const chainId = '0x' + (chain).toString(16);
			await provider.send('wallet_switchEthereumChain', [{ chainId }]);
		}
	}

	const handleAddToken = async () => {
		const provider = new BrowserProvider(walletProvider);
		await provider.send('wallet_watchAsset', {
				type: 'ERC20', // Initially only supports ERC20, but eventually more!
				options: {
					address: token.address, // The address that the token is at.
					symbol: token.symbol, // A ticker symbol or shorthand, up to 5 chars.
					decimals: token.decimals, // The number of decimals in the token
					image: token.logo, // A string url of the token logo
				},
			},
		);
	}

	return (
		<>
			{token.launchDate != null ?
				<Flex style={{ borderRadius: '10px', border: 'solid 1px #FFE814', backgroundColor: '#252732', padding: '10px' }} align="center" justify="space-evenly" gap={5}>
					<Image src={config.DexLogo} style={{ width: '18px', height: '18px' }} preview={false} />
					<Text>{`${config.DexName} pool seeded!`}</Text>
					<Link href={`https://dexscreener.com/${config.Short === "eth" ? "ethereum" : config.Short}/${token.pairAddress}`} target="_blank">View Pool</Link>
					<LinkIcon size={11} />
				</Flex>
				: <></>}
				<Card title="Swap" extra={
				<Flex align="center" gap={5}>
					<Tooltip title={`Add ${token.symbol} to Wallet`}>
						<Link onClick={() => { handleAddToken() }}><MetamaskIcon size={20} /></Link>
					</Tooltip>
					<Tooltip title="Adjust Slippage">
						<div>
							<SocialIcon size={16} onClick={() => setShowSlippage(true)} icon={SettingsIcon} />
						</div>
					</Tooltip>
				</Flex>
				} style={{ position: 'relative' }} >
				<>
					{!isConnected ? <Flex vertical justify="center" align="center" rootClassName="overlay">
						<Text style={{ marginBottom: '30px' }}>Please connect your Web3 wallet to swap.</Text>
						<ConnectButton />
					</Flex> :
						token.chain !== chainId ? <Flex justify="center" align="center" vertical rootClassName="overlay">
							<Text style={{ marginBottom: '30px' }}>You are connected to the wrong chain.</Text>
							<Button onClick={() => { handleChainChange(config.ID); }}>{`Switch to ${config.Name}`}</Button>
						</Flex> : <></>}

					<Flex vertical align="center" gap={5}>
						<TokenInput title="From" value={amountIn} setValue={setAmountIn} balance={isBuy ? nativeBalance : tokenBalance} token={isBuy ? native : token} />
						<Link onClick={() => { setIsBuy(!isBuy) }}><SwapIcon /></Link>
						<TokenInput title="To" value={amountOut} setValue={setAmountOut} balance={isBuy ? tokenBalance : nativeBalance} token={isBuy ? token : native} readOnly />
						{token.launchDate != null && showApprove ?
							<Button onClick={handleApprove} disabled={!canApprove} style={{ width: '100%' }}>
								<Text style={{ padding: '5px' }}>Approve Tokens</Text>
							</Button> : <></>
						}
						<Flex rootClassName="view-slippage" justify="space-between" align="center">
							<Flex align="center">
								<Text>Slippage Tolerance</Text>
								<SocialIcon icon={EditFilled} onClick={() => setShowSlippage(true)} size={15} style={{ margin: "5px" }} />
							</Flex>
							<Text>{`${slippage}%`}</Text>
						</Flex>
						<Button onClick={handleSwap} disabled={!canSubmit} style={{ width: '100%' }}>
							{
								token.launchDate != null ?
								<Flex align="center" justify="center">
									<Image src={config.DexLogo} style={{ width: '18px', height: '18px' }} preview={false} />
									<Text style={{ padding: '5px' }}>SWAP</Text>
								</Flex> :
								isBuy ?
								<Flex align="center" justify="center">
									<BananaIcon />
									<Text style={{ padding: '5px' }}>APE</Text>
								</Flex> :
								<Flex align="center" justify="center">
									<Image src="/img/sad.png" preview={false} style={{ width: '20px', height: '20px' }} />
									<Text style={{ padding: '5px' }}>FADE</Text>
								</Flex>
							}
						</Button>
					</Flex>
				</>
			</Card>
			<Modal title="Set Slippage" open={showSlippage} closable={false} footer={[
				<Button key="back" onClick={() => setShowSlippage(false)}>
					OK
				</Button>]}>
				<Flex style={{ padding: 0, marginTop: '10px' }} gap={5} vertical>
					
					<Slider min={1} max={50} onChange={handleEditSlippage} value={slippage}
						style={{ marginTop: '40px' }}
						styles={{
							track: {
								background: '#EAB308'
							},
							handle: {
								boxShadow: '0 0 0 1px #EAB308',
								backgroundColor: '#6B652A'
							}
						}}
						tooltip={{
							formatter: (v) => { return `${v}%` }
						}}
						marks={{ 1: '1%', 10: '10%', 20: '20%', 30: '30%', 40: '40%', 50: '50%'}}
					/>
				</Flex>
			</Modal>
		</>
	);
};

const Trades = ({ token, config, mobile }) => {
	const { latestEvent } = useSockets();
	const [trades, setTrades] = React.useState(null);

	React.useEffect(() => {
		try {
			if (token && latestEvent && latestEvent.transaction && latestEvent.token?.id == token.id) {
				setTrades([latestEvent.transaction, ...(trades ?? [])])
			}
		}
		catch (ex) {
			console.error(ex);
		}
	}, [latestEvent, token]);

	const loadData = async () => {
		try {
			const response = await axios.get(`/api/token/${token.chain}/${token.address}/trades`);
			setTrades(response.data);
		}
		catch (err) {
			console.error(err);
			setTrades(null);
		}
	};

	React.useEffect(() => {
		setTrades(null);
		loadData();
	}, [token]);

	const formatNumber = (value) => {
		return Intl.NumberFormat('en-US', {
			notation: "compact",
			maximumFractionDigits: 4
		}).format(value);
	};

	if (!trades) {
		return <Spin size="large" style={{ margin: 'auto' }} />
	}

	return (
		<Table dataSource={trades}>
			<Column title="Maker" dataIndex="to" key="to" render={(x) => (
				<Link href={`${config.ScannerUrl}/address/${x}`} target="_blank">
					<Flex align="center" gap={2}>
						<UserIcon style={{ minWidth: '20px', height: '20px', width: '20px' }} address={x} />
						{mobile ?
							<Text style={{ color: '#FFFFFF' }}>{`${x.toLowerCase() === (token?.creator ?? "").toLowerCase() ? "🤵 (Dev)" : ""}`}</Text> :
							<>
								<Text style={{ color: '#FFE814' }}>{`${x.substr(0, 6)}...${x.substr(38, 4)}`}</Text>
								<Text style={{ color: '#FFFFFF' }}>{`${x.toLowerCase() === (token?.creator ?? "").toLowerCase() ? " 🤵 (Dev)" : ""}`}</Text>
							</>
						}
					</Flex>
				</Link>
			)} />
			<Column title="Type" key="type" render={(_, row) => (
				<>{row.nativeIn > 0 ? "buy" : "sell"}</>
			)} />
			{mobile ?
				<Column title="Details" key="details" render={(_, row) => (
					<Flex vertical>
						<Text>{formatNumber(row.nativeIn > 0 ? formatEther(row.nativeIn) : formatEther(row.nativeOut))} ETH</Text>
						<Text>{formatNumber(row.tokenIn > 0 ? formatEther(row.tokenIn) : formatEther(row.tokenOut))} {token.symbol}</Text>
						<Text style={{ color: 'rgb(156, 163, 175)', fontSize: '12px' }}><Moment fromNow>{`${row.timeStamp}Z`}</Moment></Text>
					</Flex>
				)} /> :
					<>
					<Column title={config.NativeToken} key="nativeAmount" render={(_, row) => (
							<>{formatNumber(row.nativeIn > 0 ? formatEther(row.nativeIn) : formatEther(row.nativeOut))}</>
						)} />
						<Column title={token.symbol} key="tokenAmount" render={(_, row) => (
							<>{formatNumber(row.tokenIn > 0 ? formatEther(row.tokenIn) : formatEther(row.tokenOut))}</>
						)} />
						<Column title="Date" dataIndex="timeStamp" key="timeStamp" render={(x) => (
							<Moment fromNow>{`${x}${x.endsWith("Z") ? "" : "Z"}`}</Moment>
						)} />
					</>
			}

			<Column title="Tx" dataIndex="transactionHash" key="transactionHash" render={(x) => (
				<Link href={`${config.ScannerUrl}/tx/${x}`} target="_blank">{`${x.substr(2, mobile ? 6 : 10)}${mobile ? '' : '...'}`}</Link>
			)} />
		</Table>
	);
}

const Holders = ({ token, config, mobile }) => {
	const [holders, setHolders] = React.useState(null);
	const { isAdmin, sessionId, isAuthenticated, signature } = usePermissions();

	const loadData = async () => {
		try {
			const response = await axios.get(`/api/token/${token.chain}/${token.address}/holders`);
			setHolders(response.data);
		}
		catch (err) {
			console.error(err);
			setHolders(null);
		}
	};

	React.useEffect(() => {
		setHolders(null);
		loadData();
	}, [token]);

	if (!holders) {
		return <Spin size="large" style={{ margin: 'auto' }} />
	}

	return (
		<>
			<List
				size="small"
				header={<Text style={{
					fontSize: '14px',
					fontWeight: 400,
					color: '#fff',
					lineHeight: '14px',
				}}>Holders Distribution</Text>}
				bordered
				dataSource={holders}
				renderItem={(item, index) => (
					<List.Item>
						<Flex align="center" justify="space-between" style={{ width: '100%', fontSize: '14px', lineHeight: '20px', fontWeight: 400, color: "#9CA3AF" }}>
							<Flex gap={2}>
								<Text>{`${index + 1}.`}</Text>
								<Link href={`${config.ScannerUrl}/address/${item.address}`} target="_blank" style={{
									color: item.isCreator || item.isBondingCurve || item.isPair ? "#FFE814" : "#9CA3AF"
								}}>{`${item.address.substr(0, 6)}...${item.address.substr(38, 4)}`}</Link>
								{item.isBondingCurve ?
									<>
										<Text>🏦 (Bonding curve)</Text>
									</> :
									item.isCreator ?
										<>
											<Text>🤵 (Dev)</Text>
										</> :
									item.isPair ?
										<>
											<Text> <Image src={config.DexLogo} style={{ width: '18px', height: '18px' }} preview={false} /> (Pair)</Text>
											</> :
									item.address.toLowerCase() === "0x000000000000000000000000000000000000dead" || item.address === "0x0000000000000000000000000000000000000000" ?
										<>
											<Text>🔥 (Burned)</Text>
										</> :
										<></>
								}
							</Flex>
							<Text>{(item.holding * 100).toFixed(2)}%</Text>
						</Flex>
					</List.Item>
				)}
			/>
		</>
	);
};

const Chat = ({ token, config, mobile }) => {
	const { address } = useWeb3ModalAccount();
	const { walletProvider } = useWeb3ModalProvider();
	const { isAdmin, sessionId, isAuthenticated, signature } = usePermissions();
	const { latestChat } = useSockets();
	const topRef = React.useRef(null);
	const bottomRef = React.useRef(null);


	const [chats, setChats] = React.useState(null);
	const [chat, setChat] = React.useState("");
	const [showCreateChat, setShowCreateChat] = React.useState(false);

	const loadData = async () => {
		try {
			const response = await axios.get(`/api/token/${token.chain}/${token.address}/chats`);
			setChats(response.data);
		}
		catch (err) {
			console.error(err);
			setChats(null);
		}
	};

	React.useEffect(() => {
		if (latestChat !== null) {
			setChats([...chats.filter(x => x.id !== latestChat.id), latestChat]);
		}
	}, [latestChat]);

	React.useEffect(() => {
		setChats(null);
		loadData();
	}, [token]);

	const handleCancel = () => {
		setShowCreateChat(false);
	};

	const handleDeleteChat = async (id) => {
		if (isAdmin && isAuthenticated) {
			try {
				const ethersProvider = new BrowserProvider(walletProvider);
				const signer = await ethersProvider.getSigner();
				const signature = await signer.signMessage(`${id}|deletechat|${sessionId}`);
				const response = await axios.delete(`/api/admin/chat/${id}`, {
					data: { signature }
				});

				setChats([...chats.filter(x => x.id !== id)]);
			}
			catch (err) {
				console.error(err);
			}
		}
	};

	const handleCreateChat = async () => {
		try {
			const ethersProvider = new BrowserProvider(walletProvider);
			const signer = await ethersProvider.getSigner();
			const signature = await signer.signMessage(`${chat} - ${token.id}`);
			const response = await axios.post(`/api/chat/${token.id}`, {
				who: address,
				messageText: chat,
				signature
			});

			//setChats([...chats, response.data])
			setShowCreateChat(false);
			setChat("");
		}
		catch (err) {
			console.error(err);
		}
	}

	const scrollTo = (ref) => {
		ref.current?.scrollIntoView({ behavior: "smooth" })
	}

	if (!chats) {
		return <Spin size="large" style={{ margin: 'auto' }} />
	}

	return (
		<>
			<Flex vertical gap={10}>
				<Link ref={topRef} style={{ fontSize: '12px', color: '#fff', textAlign: 'right' }} onClick={() => { scrollTo(bottomRef); }}>Scroll to bottom ↓</Link>
				{chats.map(row => {
					return (
						<Card key={row.id} styles={{ body: { padding: '5px' } }}>
							<Flex vertical gap={5}>
								<Flex justify="space-between" style={{ overflow: 'visible', height: '20px'}}>
									<Flex style={{ width: '100%' }} gap={5}>
										<UserIcon style={{ minWidth: '18px', height: '18px', width: '18px' }} address={row.sender} />
										<Link href={`${config.ScannerUrl}/address/${row.sender}`} target="_blank">{`${row.sender.substr(0, 6)}...${row.sender.substr(38)}`}</Link>
										<Text style={{ color: '#FFFFFF' }}>{`${row.sender.toLowerCase() === (token?.creator ?? "").toLowerCase() ? " 🤵 (Dev)" : ""}`}</Text>
										<Moment fromNow style={{ color: '#94A3B8' }}>{`${row.timeStamp}${row.timeStamp.toString().endsWith("Z") ? "" : "Z"}`}</Moment>
									</Flex>
									{isAdmin && isAuthenticated ?
										<div className="admin-tools" style={{ height: '40px'}}>
											<Tooltip title="Delete"><Button type="primary" onClick={() => { handleDeleteChat(row.id); }} shape="circle" icon={<DeleteFilled />} size={24} /></Tooltip>
										</div> : <></>
									}
								</Flex>
								<Text>{row.messageText}</Text>
							</Flex>
						</Card>
					);
				})}
				<Link ref={bottomRef} style={{ fontSize: '12px', color: '#fff' }} onClick={() => { scrollTo(topRef); }}>Scroll to top ↑</Link>
				<Link onClick={() => setShowCreateChat(true)} style={{ textAlign: 'center', color: '#fff' }}>
					<Flex gap={5} justify="center" align="center" style={{ width: '100%'}}>
						<PenIcon size={16} />
						<>Post a reply</>
					</Flex>
				</Link>
			</Flex>
			<Modal title="Add a comment" open={showCreateChat} onOk={handleCreateChat} onCancel={handleCancel} closable={false} okButtonProps={{ type: "default" }}
			>
				<TextArea rows={3} placeholder="Comment" value={chat} onChange={(e) => setChat(e.target.value)} />
			</Modal>
		</>
	);
};

const TradeChat = ({ token, config, native }) => {
	const { isAdmin, sessionId, isAuthenticated, signature } = usePermissions();
	const { walletProvider } = useWeb3ModalProvider();
	const [messageIndex, setMessageIndex] = React.useState(0);
	const [messages, setMessages] = React.useState(null);

	const loadData = async () => {
		try {
			const response = await axios.get(`/api/token/${token.chain}/${token.address}/messages`);
			setMessages(response.data);
		}
		catch (err) {
			console.error(err);
			setMessages(null);
		}
	};

	React.useEffect(() => {
		setMessages(null);
		loadData();
	}, [token]);

	if (!messages || messages.length === 0 || messageIndex >= messages.length) {
		return <></>;
	}

	const handleDeleteMessage = async (id) => {
		if (isAdmin && isAuthenticated) {
			try {
				const response = await axios.delete(`/api/admin/message/${id}`, {
					data: {  },
				}, {
					headers: {
						"Authorization": `Bearer ${signature}`,
					}
				});

				setMessageIndex(0);
				setMessages([...messages.filter(x => x.id !== id)]);
			}
			catch (err) {
				console.error(err);
			}
		}
	};

	const message = messages[messageIndex];
	return (
		<Card title="Trade Chat">
			<Flex style={{ width: '100%' }} justify="space-evenly" gap={5}>
				<UserIcon style={{ minWidth: '18px', height: '18px', width: '18px' }} address={message.transaction.to} />
				<Flex vertical>
					<Text><Link href={`${config.ScannerUrl}/address/${message.transaction.to}`} target="_blank">{`${message.transaction.to.substr(0, 6)}...${message.transaction.to.substr(38)}`}</Link> {message.transaction.nativeIn > 0 ? `bought ${parseFloat(formatEther(message.transaction.nativeIn)).toFixed(4)} ${native.symbol}` : `sold ${parseFloat(formatEther(message.transaction.nativeOut)).toFixed(4)} ${native.symbol}`}</Text>
					<Text>{message.messageText}</Text>
				</Flex>
				{isAdmin && isAuthenticated?
					<div className="admin-tools" style={{ height: '40px' }}>
						<Tooltip title="Delete"><Button type="primary" onClick={() => { handleDeleteMessage(message.id); }} shape="circle" icon={<DeleteFilled />} size={24} /></Tooltip>
					</div> : <></>
				}
				<Moment fromNow>{`${message.transaction.timeStamp}Z`}</Moment>
			</Flex>
			<Flex justify="space-between">
				{messageIndex > 0 ? <Link onClick={() => setMessageIndex(messageIndex - 1)}>&lt;- Next</Link> : <div />}
				{messageIndex < messages.length - 1 ? <Link onClick={() => setMessageIndex(messageIndex + 1)}>Previous -&gt;</Link> : <div />}
			</Flex>
		</Card>
	);
};