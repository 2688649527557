import React from 'react';
import { Divider, Flex, Button, Image, Checkbox, theme } from 'antd';
import { useWeb3ModalAccount } from '@web3modal/ethers/react'
import { DashboardIcon, DeployerIcon, MyApesIcon, TwitterIcon, TelegramIcon, SupportIcon, TutorialIcon, ReferIcon, GitBookIcon, BridgeIcon } from '../shared/ApeIcons'
import { SocialIcon } from '../shared/SocialIcon'
import { ChainSelect } from '../shared/ChainSelect'
import { useNavigate, useLocation } from "react-router-dom";
import { CloseOutlined } from '@ant-design/icons';
import {  Typography } from 'antd';
import { ConnectMenuButton } from '../shared/ConnectButton';
import { usePermissions } from '../context/PermissionsContext';
import { useAnimation } from '../context/AnimationContext';
import { SmartWalletCreateButton } from '../shared/SmartWalletCreateButton';
const {  Text, Link } = Typography;


const MenuItem = ({ item, index, handleMenuClick }) => {
	const {
		token: { textYellow, textGray74 },
	} = theme.useToken();

	const { pathname } = useLocation();;
	const selected = pathname === item.key;

	return (
		<Link onClick={() => { handleMenuClick(item, index) }} style={{ display: "flex", alignItems: "center", padding: "18px"}}>
			<item.icon color={selected ? textYellow : textGray74} style={{fontWeight: 500, fontSize: "24px" }} />
			<Text style={{ color: selected ? "#fff" : textGray74, fontWeight: 500, fontSize: "17px", marginLeft: "20px", overflow: 'hidden', textWrap: 'nowrap' }}> {item.label}</Text>
		</Link>
	);
}

export const LeftMenu = ({ setIsModalOpen, mobile, menuCollapsed, setMenuCollapsed, handleChainChange }) => {
	const navigate = useNavigate();
	const { isAdmin, isAuthenticated, authenticate } = usePermissions();
	const { chainId } = useWeb3ModalAccount();

	const handleMenuClick = (item, index) => {
		navigate(item.key)
	};



	const menuItems = [
		{ key: "/", icon: DashboardIcon, label: "New Pairs" },
		{ key: "/my-apes", icon: MyApesIcon, label: "My Apes" },
		{ key: "/new-token", icon: DeployerIcon, label: "Create Token" },
		{ key: "/profile", icon: ReferIcon, label: "My Profile" },
		{ key: "/bridge", icon: BridgeIcon, label: "Bridge" }
	];

	return (
		<Flex vertical align="center" style={{ width: '100%'}}>
			<Flex justify="space-between" align="center" style={{ padding: '10px', width: '100%' }}>
				
				<Link onClick={() => { navigate("/"); }} style={{  width: '100%' }}>
					<Flex justify="center" align="center">
						<Image preview={false}
							width={24}
							src="/img/logo.png"
						/>
						<Typography style={{ fontSize: "16px", fontWeight: 500, padding: '0 20px', overflow: 'hidden', textWrap: 'nowrap' }}>ApeStore</Typography>
					</Flex>
				</Link>

				{mobile ?
					<Button
					type="text"
					icon={<CloseOutlined />}
					onClick={() => setMenuCollapsed(true)}
					style={{
						fontSize: '16px',
						width: 64,
						height: 64,
					}}
					/> : <></>
				}
			</Flex>
			<Divider style={{ margin: '0', paddingBottom: '10px' }} />
			<Flex justify="center" vertical style={{ width: '240px' }}>			
				{menuItems.map((item, index) => {
					return item.adminOnly && (isAdmin === false && isAuthenticated === false) ?
						<div key={item.key}></div> :
						<MenuItem key={item.key} item={item} index={index} handleMenuClick={handleMenuClick}  />;
				})}
				{isAdmin && isAuthenticated === false ?
					<Link onClick={authenticate} style={{ display: "flex", alignItems: "center", padding: "18px" }}>
						{/*<item.icon color="red" style={{ fontWeight: 500, fontSize: "24px" }} />*/}
						<Text style={{ color: "red", fontWeight: 500, fontSize: "17px", marginLeft: "20px", overflow: 'hidden', textWrap: 'nowrap' }}> Authenticate</Text>
					</Link> :
					<></>}
			</Flex>

			{mobile && menuCollapsed ? <></> :
				<Flex vertical align="center" gap={10} style={{  position: "absolute", bottom: "50px", width: '100%' }}>					
					<Flex justify="center">
						<SocialIcon size={26} title="Telegram" icon={TelegramIcon} href="https://t.me/apedotstore" target="_blank" style={{ margin: "10px" }} />
						<SocialIcon size={26} title="Twitter (X)" icon={TwitterIcon} href="https://twitter.com/apedotstore" target="_blank" style={{ margin: "10px" }} />
						<SocialIcon size={26} title="Support" icon={SupportIcon} href="https://t.me/ApeStoreSupport" target="_blank" style={{ margin: "10px" }} />
						<SocialIcon size={26} title="Docs" icon={GitBookIcon} href="https://ape-store.gitbook.io/ape.store-docs" target="_blank" style={{ margin: "10px" }} />
					</Flex>

					<Divider style={{ margin: '0', paddingBottom: '10px' }} />

					<Flex vertical style={{ width: '100%' }} gap={10}>
						<ConnectMenuButton />
						<SmartWalletCreateButton />

						<Button style={{ width: '200px', borderColor: '#756d22', backgroundColor: '#756d22', borderRadius: '0 10px 10px 0px'}} onClick={() => { setIsModalOpen(true); }}>
							<Flex justify="center" gap={5}>
								<TutorialIcon />
								<Text style={{ lineHeight: '24px' }}>App Tutorials</Text>
							</Flex>
						</Button>
					</Flex>

				</Flex>
			}
		</Flex>
	);
}
