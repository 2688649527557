import React from 'react';
import { useLocalState } from '../../App'

const AnimationContext = React.createContext();

export const AnimationProvider = ({ children }) => {
	const [enableAnimation, setEnableAnimation] = useLocalState("animations", true);

	return (
		<AnimationContext.Provider value={{ enableAnimation, setEnableAnimation }}>
			{children}
		</AnimationContext.Provider>
	);
};

export const useAnimation = () => {
	return React.useContext(AnimationContext);
};