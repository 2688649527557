import React from 'react';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ClockCircleOutlined, DollarOutlined, StarFilled, ThunderboltFilled, MoneyCollectFilled, DatabaseFilled, RocketFilled, CrownFilled, PlusCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { Avatar, Popover, Flex, Typography, Spin, Tooltip, Modal, Input, Button } from 'antd';
import { TokenLogo } from './TokenLogo';
import {  TxIcon, ReferrersIcon } from '../shared/ApeIcons';
import { useWeb3ModalProvider, useWeb3ModalAccount } from '@web3modal/ethers/react'
import { BrowserProvider } from 'ethers'
import { usePermissions } from '../context/PermissionsContext';
import { useSockets } from '../context/SocketsContext';
import { ChainDisplay } from './ChainSelect';
import { BlockiesSvg } from 'blockies-react-svg'

const { Text, Paragraph } = Typography;

export const UserIcon = ({ address, style }) => {
	const { mobile } = useSockets();
	const navigate = useNavigate();
	const { walletProvider } = useWeb3ModalProvider();
	const { sessionId, isAdmin, isAuthenticated, signature } = usePermissions();
	const [open, setOpen] = React.useState(false);
	const [name, setName] = React.useState(null);
	const [data, setData] = React.useState(null);
	const [showEditSocials, setShowEditSocials] = React.useState(false);

	const [twitter, setTwitter] = React.useState("");
	const [telegram, setTelegram] = React.useState("");
	const [website, setWebsite] = React.useState("");

	const loadData = async () => {
		try {
			const response = await axios.get(`/api/user/${address}`);
			setData(response.data);
			setName(response.data.user?.name ?? null);
			setTwitter(response.data.user?.twitter ?? null);
			setTelegram(response.data.user?.telegram ?? null);
			setWebsite(response.data.user?.website ?? null);
		}
		catch (err) {
			console.error(err);
			setData(null);
		}
	};

	React.useEffect(() => {
		if (open && data === null) {
			loadData();
		}
	}, [address, open, data]);

	const handleEditSocials = async () => {
		try {
			const ethersProvider = new BrowserProvider(walletProvider);
			const signer = await ethersProvider.getSigner();
			const signature = await signer.signMessage(`${twitter}|${telegram}|${website}|${sessionId}`);
			await axios.post(`/api/user/socials`, {
				address,
				twitter,
				telegram,
				website,
				signature
			});
			setShowEditSocials(false);
		}
		catch (err) {
			console.error(err);
		}
	}

	const handleBlacklistUser = async (blacklisted, time) => {
		if (isAdmin && isAuthenticated) {
			try {
				const ethersProvider = new BrowserProvider(walletProvider);
				const signer = await ethersProvider.getSigner();
				const signature = await signer.signMessage(`${address}|blacklist-${blacklisted? "add" : "remove"}|${time}|${sessionId}`);
				const response = await axios.post(`/api/admin/blacklist/${address}`, { signature, blacklisted, time }, {
					headers: {
						"Authorization": `Bearer ${signature}`,
					}
				});
				loadData();
			}
			catch (err) {
				console.error(err);
			}
		}
	};

	const content = (
		<Flex vertical rootClassName={`root tier-${data?.tier}${data?.freeChamp ? " free-champ" : ""}${data?.awardChamp ? " award-champ" : ""}`}  onClick={(evt) => { evt.stopPropagation(); }}>
			{data === null ?
				<Spin /> :
				<>
					<Flex rootClassName="user-banner">
						<div className="user-avatar">
							<Avatar style={{ width: '64px', height: '64px' }} icon={<BlockiesSvg scale={8} size={9} address={address.toLowerCase()} />}>{address.toLowerCase()}</Avatar>
						</div>
					</Flex>
					<div className="hero-border">
						<Flex vertical rootClassName="user-stats">
							<Flex justify="space-between">
								<NameDisplay address={data?.user?.address} name={data.user?.name ?? null} />
								<Flex rootClassName="stat awards" align="center" gap={5} style={{height: '12px'}}>
									<Tooltip title="Awards">
										🏆
									</Tooltip>
									<Text>{ data?.totalAwards}</Text>
								</Flex>							
							</Flex>
							<hr />
							{isAdmin && isAuthenticated ?
								<div className="admin-tools" style={{ height: '40px' }}>
									<Tooltip title="Blacklist">
										<Button type="primary" onClick={() => handleBlacklistUser(true, 24)} shape="circle" icon={<CloseCircleFilled />} size={24} />
									</Tooltip>
									<Tooltip title="Unblacklist">
										<Button type="primary" onClick={() => handleBlacklistUser(false, 0)} shape="circle" icon={<CloseCircleFilled />} size={24} />
									</Tooltip>
								</div> :
								<></>
							}
							<Text rootClassName="section">Stats</Text>
							<Stats data={data} />
							<Text rootClassName="section">{`Tokens Deployed (${data?.tokens.length})`}</Text>
							<Avatar.Group maxCount={14} size="large" rootClassName="profile-tokens">
								{data?.tokens.map((token) => {
									return (
										<Tooltip key={token.id} title={`${token.name} (${token.symbol})`}>
											<div style={{ cursor: 'pointer', borderRadius: '8px', border: token.launchDate ? "2px solid #86EFAC" : "none" }} onClick={() => { navigate(`/${token.chain}/${token.address}`); }}>
												<TokenLogo size={46} value={token} />
											</div>
										</Tooltip>
									);
								})}
							</Avatar.Group>
						</Flex>
					</div>
				</>
			}
		</Flex>
	);

	const handleOpenChange = (newOpen) => {
		console.log("new open")
		setOpen(newOpen);
	};

	const handleOpenClick = (evt) => {
		evt.stopPropagation();
	};

	return (
		<>
			<Popover
				content={content}
				rootClassName="user-popover"
				trigger={mobile ? "click" : "hover"}
				open={open}
				onOpenChange={handleOpenChange}
				onClick={handleOpenClick}
				fresh={true}
			>
				<Avatar style={style} icon={<BlockiesSvg scale={2} size={9} address={address.toLowerCase()} />}>{address.toLowerCase()}</Avatar>
			</Popover>
			<Modal title="Edit Socials" open={showEditSocials} onOk={handleEditSocials} onCancel={() => { setShowEditSocials(false); }} closable={false} okButtonProps={{ type: "default" }}>
				<Flex onClick={(evt) => { evt.stopPropagation(); }} style={{ padding: 0, marginTop: '10px' }} gap={5} vertical>
					<Text>Telegram Link</Text>
					<Input placeholder="Optional" value={telegram} onChange={(e) => setTelegram(e.target.value)} />
					<Text>Twitter Link</Text>
					<Input placeholder="Optional" value={twitter} onChange={(e) => setTwitter(e.target.value)} />
					<Text>Website</Text>
					<Input placeholder="Optional" value={website} onChange={(e) => setWebsite(e.target.value)} />
				</Flex>
			</Modal>
		</>
	);
}

export const NameDisplay = ({ name, setName, address}) => {
	const { walletProvider } = useWeb3ModalProvider();
	const { sessionId } = usePermissions();
	const { address: connectedAddress, isConnected } = useWeb3ModalAccount();

	const handleEditName = async (newName) => {
		try {
			const ethersProvider = new BrowserProvider(walletProvider);
			const signer = await ethersProvider.getSigner();
			const signature = await signer.signMessage(`${newName}|${sessionId}`);
			await axios.post(`/api/user/name`, {
				address,
				name: newName,
				signature
			});
			setName(newName);
		}
		catch (err) {
			console.error(err);
		}
	}

	return (
		address ?
			<Flex vertical>
				{isConnected && (connectedAddress ?? "").toLowerCase() === address.toLowerCase() ?
					<div>
						<Paragraph rootClassName="user-nickname" editable={{ onChange: handleEditName }}>
							{name ? name : `${address.substr(0, 6)}...${address.substr(38)}`}
						</Paragraph>
					</div> :
					<Text rootClassName="user-nickname">{name ? name : `${address.substr(0, 6)}...${address.substr(38)}`}</Text>
				}
				<Text rootClassName="user-address" copyable={{ text: address }} >{`${address.substr(0, 6)}...${address.substr(38)}`}</Text>
			</Flex> :
			<></>
	);
}

export const Stats = ({ data }) => {
	return (
		<Flex justify="flex-start" wrap="wrap">
			<Flex rootClassName="stat" gap={5} align="center">
				<Tooltip title="First seen">
					<ClockCircleOutlined style={{ fontSize: '16px' }} />
				</Tooltip>
				<Text>{new Date(data?.user?.createDate + 'Z').toLocaleDateString()}</Text>
			</Flex>
			<Flex rootClassName="stat" gap={5} align="center">
				<Tooltip title="Number of transactions">
					<div style={{ lineHeight: '0px' }}><TxIcon size={16} /></div>
				</Tooltip>
				<Text>{data?.transactions ?? 0}</Text>
			</Flex>
			<Flex rootClassName="stat" gap={5} align="center">
				<Tooltip title="Volume">
					<DollarOutlined style={{ fontSize: '16px' }} />
				</Tooltip>
				<Text>${Math.round(data?.volume ?? 0)}</Text>
			</Flex>
			<Flex rootClassName="stat" gap={5} align="center">
				<Tooltip title="Referrals">
					<div style={{ lineHeight: '0px' }}><ReferrersIcon size={16} /></div>
				</Tooltip>
				<Text>{Math.round(data?.referrals ?? 0)}</Text>
			</Flex>
		</Flex>
	);
}

const Award = ({ value }) => {

	const matches = value.match(/^([^_]*)_([^_]*)_([^_]*)$/);
	const chain = matches[1];
	const award = matches[2];
	const level = matches[3];

	const awards = [
		{ award: "TokensDeployed", icon: PlusCircleFilled },
		{ award: "TimesKing", icon: CrownFilled },
		{ award: "TimesLaunched", icon: RocketFilled },
		{ award: "UniqueTokensBought", icon: MoneyCollectFilled },
		{ award: "Transactions", icon: DatabaseFilled },
		{ award: "Volume", icon: ThunderboltFilled }
	];
	const AwardIcon = awards.find(x => x.award === award).icon;

	const stars = [];
	for (let i = 0; i <= level; i++) {
		stars.push('');
	}

	console.log(chain)

	return (
		<Tooltip key={value} title={award}>
			<div style={{ position: 'relative', width: '42px', height: '42px' }}>\
				<div style={{ position: 'absolute', top: '0' }}><Badge size={42}></Badge></div>
				<div style={{ position: 'absolute', top: '0' }}><ChainDisplay chain={chain} /></div>
				<div style={{ position: 'absolute', top: '0', right: 0 }}><AwardIcon style={{ fontSize: '20px' }} /></div>
				<div style={{ position: 'absolute', top: '20px', width: '42px', display: 'flex', justifyContent: 'center', alignContent: 'center', flexWrap: 'wrap' }}>{stars.map(star => { return <StarFilled style={{ color: 'yellow' }} /> })}</div>
			</div>
		</Tooltip>
	);

}

const Badge = ({ size }) => {

	return (
		<svg height={`${size}px`} width={`${size}px`} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
			viewBox="0 0 419.226 419.226" xmlSpace="preserve">
			<g>
				<g>
					<path style={{ fill: '#010002' }} d="M209.741,419.226l-1.152-0.197c-2.029-0.352-202.683-37.52-202.683-240.823
			c0-59.806,18.646-117.159,52.514-161.481l2.787-3.652l4.242,1.766c18.545,7.709,38.188,11.623,58.386,11.623
			c29.369,0,57.908-8.425,82.534-24.357L209.616,0l3.24,2.1c24.625,15.932,53.165,24.357,82.534,24.357
			c20.192,0,39.835-3.908,58.38-11.623l4.242-1.766l2.793,3.652c33.862,44.322,52.514,101.675,52.514,161.481
			c0,194.245-200.403,240.328-202.426,240.775L209.741,419.226z"/>
				</g>
			</g>
		</svg>
	);
}