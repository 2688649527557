import React from 'react';
import { Button, Flex, Typography } from 'antd';
import { useWeb3Modal, useDisconnect, useWeb3ModalAccount } from '@web3modal/ethers/react'
import { DisconnectOutlined, ApiOutlined } from '@ant-design/icons';
const { Text } = Typography;


export const ConnectButton = () => {
	const { open } = useWeb3Modal();
	const { disconnect } = useDisconnect();
	const { isConnected } = useWeb3ModalAccount()

	return (
		isConnected ? <Button style={{ width: '100px' }} onClick={() => disconnect()}>Disconnect</Button> : <Button style={{ width: '100px' }} onClick={() => open()}>Connect</Button>
	);
}

export const ConnectMenuButton = () => {
	const { open } = useWeb3Modal();
	const { disconnect } = useDisconnect();
	const { isConnected } = useWeb3ModalAccount()

	return (
		isConnected ?
			<Button style={{ width: '200px', borderColor: '#756d22', backgroundColor: '#756d22', borderRadius: '0 10px 10px 0px', marginTop: '10px' }} onClick={() => disconnect()}>
				<Flex justify="center" gap={5}>
					<DisconnectOutlined />
					<Text style={{ lineHeight: '24px' }}>Disconnect Wallet</Text>
				</Flex>
			</Button> :
			<Button style={{ width: '200px', borderColor: '#756d22', backgroundColor: '#756d22', borderRadius: '0 10px 10px 0px', marginTop: '10px' }} onClick={() => open()}>
				<Flex justify="center" gap={5}>
					<ApiOutlined />
					<Text style={{ lineHeight: '24px' }}>Connect Wallet</Text>
				</Flex>
			</Button>
	);
}