import React from 'react';
import { Typography } from 'antd';

const { Text } = Typography;

export const Bridge = () => {
	//const { address, isConnected } = useWeb3ModalAccount();
	
	return (
		<>
			<Text style={{
				fontSize: '17px',
				fontWeight: 500,
				lineHeight: '20.57px',
				textAlign: 'left',
				marginTop: '20px',
				display: 'block',
				marginLeft: '20px'
			}}>
				Bridge
			</Text>

			<div style={{ zIndex: 1, position: "relative", background: 'rgb(27, 29, 40)', minHeight: '100vh', maxWidth: '600px', paddingTop:'20px', margin: '0 auto' }}>
				<iframe title="Bridge Tokens with Houdinia" src="https://houdiniswap.com/?partnerId=2&widgetMode=true&tokenOut=ETHBASE" style={{ width: '100%', height: '650px', borderRadius: '30px' }} allow="clipboard-write" />
				<Text>Powered by <a href="https://houdiniswap.com/">Houdini Swap</a></Text>
			</div>
		</>
	);
}