import React from 'react';
import axios from 'axios';
import { ApeGrid } from './shared/ApeGrid'
import { useWeb3ModalAccount } from '@web3modal/ethers/react'
import { useGrid } from './context/GridContext';
import { Typography, Flex } from 'antd';
import { ConnectButton } from './shared/ConnectButton';

const { Text } = Typography;

export const MyApes = () => {
	const { address, isConnected } = useWeb3ModalAccount();
	const { sort, setSort, chain, setChain, order, setOrder, search, setSearch, filter, setFilter, page, setPage } = useGrid();

	const [items, setItems] = React.useState([]);
	const [sorted, setSorted] = React.useState([])

	React.useEffect(() => {
		const sd = (a, b) => {
			if (order === 0) {
				return a - b;
			} else {
				return b - a;
			}
		}

		let i = [...items];

		if (search && search !== "") {
			i = i.filter(x => x.symbol.toLowerCase().includes(search.toLowerCase()) || x.name.toLowerCase().includes(search.toLowerCase()));
		}

		if (filter === 1) {
			i = i.filter(x => x.isDead === false);
		} else if (filter === 2) {
			i = i.filter(x => x.launchDate !== null);
		}

		if (chain > 0) {
			i = i.filter(x => x.chain === chain);
		}

		if (sort === 0) {
			i = i.sort((a, b) => { return sd(new Date(a.deployDate), new Date(b.deployDate)); });
		} else if (sort === 1) {
			i = i.sort((a, b) => { return sd(new Date(a.launchDate), new Date(b.launchDate)); });
		} else if (sort === 2) {
			i = i.sort((a, b) => { return sd(a.marketCap, b.marketCap); });
		} else if (sort === 3) {
			i = i.sort((a, b) => { return sd(a.chatCount, b.chatCount); });
		}

		setSorted(i);

	}, [items, page, sort, order, search, filter, chain]);

	React.useEffect(() => {
		const loadData = async () => {
			if (isConnected) {
				const response = await axios.get(`/api/tokens/${address}`);
				setItems(response.data);
			} else {
				setItems([]);
			}
		}

		loadData();
	}, [isConnected, address]);

	return (
		<div style={{ zIndex: 1, position: "relative", background: 'rgb(27, 29, 40)', marginTop: '-64px', minHeight: '100vh', paddingTop:'64px' }}>
			{!isConnected ? <Flex vertical justify="center" align="center" rootClassName="overlay">
				<Text style={{ marginBottom: '30px' }}>Please connect your Web3 wallet to view your tokens.</Text>
				<ConnectButton />
			</Flex>: <></>}
			<ApeGrid title="My Apes" items={sorted} page={page} setPage={setPage} sort={sort} setSort={setSort} order={order} setOrder={setOrder} search={search} setSearch={setSearch} filter={filter} setFilter={setFilter} chain={chain} setChain={setChain} pages={sorted.length} showPager={false} />
		</div>
	);
}