import React from 'react';
import { Col, Row, Avatar, Flex, Select, Input, Switch, Tooltip, theme } from 'antd';
import { TwitterIcon, TelegramIcon, WebIcon, SearchIcon, TooltipIcon } from './ApeIcons';
import { TokenLogo } from './TokenLogo';
import Blockies from 'react-blockies';
import { useNavigate } from "react-router-dom";
import { Typography } from 'antd';
const { Link, Text } = Typography;

export const SocialIcon = ({ icon, title, href, onClick, style, size = 22, component = Link}) => {
	const {
		token: { textGray4e },
	} = theme.useToken();

	const [hover, setHover] = React.useState(0);
	const Icon = icon;
	const Component = component;

	const handleClick = (evt) => {
		if (onClick) {
			onClick();
		} else {
			evt.stopPropagation();
			evt.preventDefault();
			window.open(href, "_blank");
		}
	};

	return (
		<Tooltip title={title}>
			<Component href={href} onClick={handleClick} target="_blank" style={style} onMouseOver={() => setHover(true)} onMouseOut={() => setHover(false)} >
				<Icon color={hover ? "#fff" : textGray4e} size={size} />
			</Component>
		</Tooltip>
	);
}
