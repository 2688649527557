import React from 'react';
import { Flex, Typography, InputNumber, Card } from 'antd';
import { TokenLogo } from './TokenLogo';

const { Link, Text } = Typography;

export const TokenInput = ({ token, title, value, setValue, balance, readOnly = false, percentOf = 0 }) => {
	return (
		<Card style={{ width: "100%" }}>
			<Flex vertical gap={5}>
				<Flex justify="space-between" gap={5}>
					<Text>{title}</Text>
					<Flex gap={5}>
						{percentOf > 0 ?
							<>
								<Text>{`${((value / percentOf) * 100).toFixed(2)}% of supply`}</Text>
							</> :
							<>
								<Text>{`Balance: ${parseFloat(balance).toFixed(3)}`}</Text>
								<Link onClick={() => { setValue(balance); }}>MAX</Link>
							</>
						}

					</Flex>
				</Flex>
				<Flex justify="space-between" gap={5}>
					<InputNumber min={0} status={!readOnly && value > balance ? "warning" : ""} value={value} onChange={(v) => { setValue(v); }} controls={false} stringMode style={{ width: '100%' }} readOnly={readOnly} />
					<Card styles={{ body: { padding: '5px' } }} style={{ maxWidth: '100px'}}>
						<Flex justify="center" align="center" gap={5} wrap="nowrap">
							<TokenLogo value={token} size={20} />
							<Text style={{ textWrap: 'nowrap'  }}>{token.symbol}</Text>
						</Flex>
					</Card>
				</Flex>
			</Flex>
		</Card>
	);
};