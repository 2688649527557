import { createWalletClient, custom } from 'viem'
import { mainnet } from 'viem/chains'
import { walletActionsEip5792 } from 'viem/experimental'
import { ENTRYPOINT_ADDRESS_V07 } from 'permissionless'
import { paymasterActionsEip7677 } from 'permissionless/experimental'

export const getWalletClient = (provider) => {
	console.log(provider)
	return createWalletClient({
		chain: mainnet,
		transport: custom(provider),
	}).extend(paymasterActionsEip7677(ENTRYPOINT_ADDRESS_V07)).extend(walletActionsEip5792());;
}
