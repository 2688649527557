import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import { Modal, Layout, Typography, Flex, Button, theme } from 'antd';
import { BananaIcon } from './components/shared/ApeIcons';
import { LeftMenu } from './components/layout/LeftMenu'
import { useWeb3ModalProvider } from '@web3modal/ethers/react'
import { TopMenu } from './components/layout/TopMenu'
import { BrowserProvider } from 'ethers'
import { SocketsProvider } from './components/context/SocketsContext';
import { PermissionsProvider } from './components/context/PermissionsContext';
import { AnimationProvider } from './components/context/AnimationContext';
import { GridProvider } from './components/context/GridContext';
import { YouTubeEmbed } from './components/shared/YouTubeEmbed';

const { Sider } = Layout;
const { Text } = Typography;
const { Content } = Layout;

export const useSessionState = (defaultValue, key) => {
	const [value, setValue] = React.useState(() => {
		const stickyValue = window.sessionStorage.getItem(key);
		return stickyValue !== null ? JSON.parse(stickyValue) : defaultValue;
	});
	React.useEffect(() => {
		window.sessionStorage.setItem(key, JSON.stringify(value));
	}, [key, value]);
	return [value, setValue];
}

export const useLocalState = (defaultValue, key) => {
	const [value, setValue] = React.useState(() => {
		const stickyValue = window.localStorage.getItem(key);
		try {
			return stickyValue !== null ? JSON.parse(stickyValue) : defaultValue;
		}
		catch {
			return defaultValue;
		}
	});
	React.useEffect(() => {
		window.localStorage.setItem(key, JSON.stringify(value));
	}, [key, value]);
	return [value, setValue];
}

export const App = () => {
	const { walletProvider } = useWeb3ModalProvider();

	const [mobile, setMobile] = React.useState(false);
	const [isModalOpen, setIsModalOpen] = useSessionState(true, "instructions.open");
	const [menuCollapsed, setMenuCollapsed] = React.useState(true);

	const {
		token: { bgContent, bgLeftMenu },
	} = theme.useToken();

	const leftMenuWidth = mobile ? menuCollapsed ? 0 : '100%' : 250;

	const handleChainChange = async (chain) => {
		if (chain != 0) {
			const provider = new BrowserProvider(walletProvider);
			const chainId = '0x' + (chain).toString(16);
			await provider.send('wallet_switchEthereumChain', [{ chainId }]);
		}
	}

	return (
		<SocketsProvider mobile={mobile}>
			<PermissionsProvider>
				<AnimationProvider>
					<GridProvider>
						<Layout style={{ width: "100%", height: "100%", minHeight: '100vh' }}>			
							<Layout>
								<Sider onBreakpoint={(broken) => { setMobile(broken) }} collapsed={mobile && menuCollapsed} breakpoint="md" collapsedWidth={0} trigger={null} width={leftMenuWidth} style={{ backgroundColor: bgLeftMenu, position: 'fixed', height: "100%", zIndex: 101 }}>
									<LeftMenu setIsModalOpen={setIsModalOpen} mobile={mobile} menuCollapsed={menuCollapsed} setMenuCollapsed={setMenuCollapsed} handleChainChange={handleChainChange} />
								</Sider>
								<Layout style={{ marginLeft: `${leftMenuWidth}px` }}>
									<TopMenu mobile={mobile} setMenuCollapsed={setMenuCollapsed} handleChainChange={handleChainChange} />
									<Content
										style={{
											padding: 0,
											margin: 0,
											backgroundColor: bgContent
										}}
									>
										<Routes>
											{AppRoutes.map((route, index) => {
												const { element, ...rest } = route;
												return <Route key={index} {...rest} element={element} />;
											})}
										</Routes>
									</Content>
								</Layout>
							</Layout>
						</Layout>
						<Modal title="How It Works" open={isModalOpen} onCancel={() => setIsModalOpen(false)} footer={null} closable={false}>
							<Flex vertical gap={10} style={{ padding: '20px' }}>
								<YouTubeEmbed embedId="JvCW_w5Z0mo" />
								<Text>Ape.Store prevents rugs by making sure that all created tokens are safe. Each token on Ape.Store
									is a fair-launch with no presale and full transparency on allocation.</Text>
								<ol>
									<li>Choose the token you like</li>
									<li>Buy the token on the selected chain</li>
									<li>Sell at any time to lock in your profits or losses</li>
									<li>When enough people buy, it reaches a market cap of $69k</li>
									<li>$12k of liquidity is then deposited on UNISWAP and burned</li>
								</ol>
							</Flex>
							<Button style={{ width: '100%'}} onClick={() => setIsModalOpen(false)}>
								<Flex align="center" justify="center">
									<BananaIcon />
									<Text style={{ padding: '5px' }}>GO APE</Text>
								</Flex>
							</Button>
						</Modal>
					</GridProvider>
				</AnimationProvider>
			</PermissionsProvider>
		</SocketsProvider>
	);
}
