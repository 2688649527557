import axios from 'axios';
import { formatEther, parseEther } from 'ethers/utils';

const lastBarsCache = new Map();
const channelToSubscription = new Map();

const configurationData = {
	supported_resolutions: ["1S", "1", "5", "30", "1H", "4H", "1D", "3D", "1W", "1M"],
	exchanges: [
		{
			value: "Ape.Store",
			name: "Ape.Store",
			desc: "Ape.Store",
		}
	],
	symbols_types: [
		{
			name: "crypto",
			value: "crypto",
		}
	]
};

const parseFullSymbol = (fullSymbol) => {
	const match = fullSymbol.match(/^(\w+):(\w+)\/(\w+)$/);
	if (!match) {
		return null;
	}

	return {
		exchange: match[1],
		fromSymbol: match[2],
		toSymbol: match[3],
	};
}

const Datafeed = {
	allSymbols: [],
	onReady: (callback) => {
		setTimeout(() => callback(configurationData));
	},

	searchSymbols: async (
		userInput,
		exchange,
		symbolType,
		onResultReadyCallback
	) => {
		const symbols = await axios.get(`/api/chart/symbols`);
		const newSymbols = symbols.data.map((symbol) => {
			return {
				symbol: symbol.address,
				full_name: symbol.name,
				description: symbol.relationships.dex.data.id,
				exchange: "Ape.Store",
				type: "crypto",
			};
		});
		Datafeed.allSymbols = [...Datafeed.allSymbols, ...symbols];
		onResultReadyCallback(newSymbols);
	},

	resolveSymbol: async (
		symbolName,
		onSymbolResolvedCallback,
		onResolveErrorCallback
	) => {
		const response = await axios.get(`/api/chart/symbols/${symbolName}`);
		const symbolInfo = {
			ticker: response.data.address,
			name: response.data.name,
			id: response.data.address,
			chain: response.data.chain,
			description: `${response.data.name} (${response.data.symbol})`,
			type: "crypto",
			session: "24x7",
			timezone: "Etc/UTC",
			exchange: "Ape.Store",
			minmov: 1,
			pricescale: 1000000000,
			visible_plots_set: 'ohlcv',
			has_seconds: true,
			has_intraday: true,
			has_daily: true,
			has_weekly_and_monthly: true,
			supported_resolutions: configurationData.supported_resolutions,
			volume_precision: 2,
			
		};

		setTimeout(() => onSymbolResolvedCallback(symbolInfo), 0);
		
	},

	getBars: async (
		symbolInfo,
		resolution,
		periodParams,
		onHistoryCallback,
		onErrorCallback
	) => {
		const { from, to, firstDataRequest } = periodParams;
		try {
			const response = await axios.get(`/api/chart/${symbolInfo.chain}/${symbolInfo.id}?from=${from}&to=${to}&resolution=${resolution}`);
			if (response.data.length === 0) {
				onHistoryCallback([], {
					noData: true,
				});
				return;
			}

			let bars = [];
			response.data.forEach((bar) => {
				if (bar[0] >= from && bar[0] < to) {
					bars.push(
						{
							time: bar[0] * 1000,
							low: bar[3],
							high: bar[2],
							open: bar[1],
							close: bar[4],
							volume: bar[5]
						},
					);
				}
			});

			if (firstDataRequest) {
				lastBarsCache.set(symbolInfo.id.toLowerCase(), {
					...bars[bars.length - 1],
				});
			}
			onHistoryCallback(bars, {
				noData: false,
			});
			return;
		} catch (error) {
			console.log(error)
			onErrorCallback(error);
		}
	},

	subscribeBars: (
		symbolInfo,
		resolution,
		onRealtimeCallback,
		subscriberUID,
		onResetCacheNeededCallback
	) => {
		const channelString = symbolInfo.id.toLowerCase();
		const lastDailyBar = lastBarsCache.get(channelString);
		const handler = {
			id: subscriberUID,
			callback: onRealtimeCallback,
		};
		let subscriptionItem = channelToSubscription.get(channelString);
		if (subscriptionItem) {
			// Already subscribed to the channel, use the existing subscription
			subscriptionItem.handlers.push(handler);
			return;
		}
		subscriptionItem = {
			subscriberUID,
			resolution,
			lastDailyBar,
			handlers: [handler],
		};

		channelToSubscription.set(channelString, subscriptionItem);
		console.log('[subscribeBars]: Subscribe to streaming. Channel:', channelString);
	},

	unsubscribeBars: (subscriberUID) => {
		// Find a subscription with id === subscriberUID
		for (const channelString of channelToSubscription.keys()) {
			const subscriptionItem = channelToSubscription.get(channelString);
			const handlerIndex = subscriptionItem.handlers.findIndex(handler => handler.id === subscriberUID);

			if (handlerIndex !== -1) {
				// Remove from handlers
				subscriptionItem.handlers.splice(handlerIndex, 1);

				if (subscriptionItem.handlers.length === 0) {
					// Unsubscribe from the channel if it is the last handler
					console.log('[unsubscribeBars]: Unsubscribe from streaming. Channel:', channelString);
					channelToSubscription.delete(channelString);
					break;
				}
			}
		}
	},



	liveUpdate: async (data) => {
		if (!data) return;

		const channelString = data.token.address.toLowerCase();
		const subscriptionItem = channelToSubscription.get(channelString);
		if (subscriptionItem === undefined) {
			return;
		}

		const tradeTime = new Date(data.transaction.timeStamp).getTime();
		const tradePrice = formatEther(data.transaction.priceAfter) * (data.transaction.nativePrice ?? 1);
		const volume = data.transaction.nativeVolume * (data.transaction.nativePrice ?? 1);

		const lastDailyBar = subscriptionItem.lastDailyBar;

		const getNextDailyBarTime = (barTime) => {
			const date = new Date(barTime).getTime();
			switch (subscriptionItem.resolution) {
				case "1S":
					return (date + 1_000);
				case "1":
					return (date + 60_000);
				case "5":
					return (date + 300_000);
				case "30":
					return (date + 1800_000);
				case "60":
					return (date + 3600_000);
				case "240":
					return (date + 14400_000);
				case "1D":
					return (date + 86400_000);
				case "3D":
					return (date + 259200_000);
				case "1W":
					return (date + 604800_000);
				case "1M":
					return (date + 2592000_000);
				default:
					return date;
			}
		}

		const nextDailyBarTime = getNextDailyBarTime(lastDailyBar.time);
		let bar;
		if (tradeTime >= nextDailyBarTime) {
			const open = formatEther(data.transaction.priceBefore) * (data.transaction.nativePrice ?? 1);
			bar = {
				time: nextDailyBarTime,
				open,
				high: tradePrice,
				low: tradePrice,
				volume: volume,
				close: tradePrice,
			};
		} else {
			bar = {
				...lastDailyBar,
				high: Math.max(lastDailyBar.high, tradePrice),
				low: Math.min(lastDailyBar.low, tradePrice),
				volume: lastDailyBar.volume += volume,
				close: tradePrice,
			};
		}
		subscriptionItem.lastDailyBar = bar;
		subscriptionItem.handlers.forEach(handler => handler.callback(bar));
	}
};

export default Datafeed;