/* global SessionID */

import React from 'react';
import { useSessionState } from '../../App'
import { BrowserProvider } from 'ethers'
import { useWeb3ModalAccount, useWeb3ModalProvider } from '@web3modal/ethers/react'
import axios from 'axios';

const PermissionsContext = React.createContext();

export const PermissionsProvider = ({ children }) => {
	const { address, isConnected } = useWeb3ModalAccount();
	const [isAdmin, setIsAdmin] = React.useState(false);
	const { walletProvider } = useWeb3ModalProvider();
	const [isAuthenticated, setIsAuthenticated] = useSessionState(false, "authenticated");
	const [signature, setSignature] = useSessionState(null, "signature");
	const sessionId = SessionID;

	React.useEffect(() => {
		const checkUser = async () => {
			if (isConnected) {
				try {
					const response = await axios.get(`/api/permissions?address=${address}`);
					setIsAdmin(response.data.isAdmin);
				}
				catch (err) {
					console.error(err);
					setIsAdmin(false);
					setIsAuthenticated(false);
				}
			} else {
				setIsAdmin(false);
				setIsAuthenticated(false);
			}
		};

		checkUser();

	}, [address, isConnected]);

	const authenticate = async () => {
		try {
			const ethersProvider = new BrowserProvider(walletProvider);
			const signer = await ethersProvider.getSigner();
			const signature = await signer.signMessage(`${sessionId}`);
			await setSignature(signature);
			await setIsAuthenticated(true);
		}
		catch (err) {
			console.log(err)
			setIsAuthenticated(false);
		}
	}

	return (
		<PermissionsContext.Provider value={{ sessionId, isAdmin, isAuthenticated, authenticate, signature }}>
			{children}
		</PermissionsContext.Provider>
	);
};

export const usePermissions = () => {
	return React.useContext(PermissionsContext);
};