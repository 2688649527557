import React from "react";
import PropTypes from "prop-types";
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'

export const YouTubeEmbed = ({ embedId }) => (
	<LiteYouTubeEmbed
		id={embedId}
		title="Ape.Store Tutorial"
	/>
);