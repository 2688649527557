/*global ApeConfig, GtmKey*/

import "@fontsource/inter/400.css"
import "@fontsource/inter/500.css"
import "@fontsource/inter/600.css"
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { App }  from './App';
import { ConfigProvider } from 'antd';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { theme } from 'antd';
import { createWeb3Modal, defaultConfig } from '@web3modal/ethers/react'
const projectId = 'dba6f79f78209d89a5dd81e0c7a00d9a'
const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

const chains = ApeConfig.filter(x => x.Active).map(x => {
	return {
		chainId: x.ID,
		name: x.Name,
		currency: x.NativeToken,
		explorerUrl: x.ScannerUrl,
		rpcUrl: x.PublicRpc,
		auxiliaryFunds: { supported: true }
	}
});

const metadata = {
	name: 'Ape.Store',
	description: 'Ape.Store',
	url: 'https://ape.store',
	icons: ['https://ape.store/img/logo.png']
}

const ethersConfig = defaultConfig({
	metadata,
	enableCoinbase: true,
	rpcUrl: "https://mainnet.base.org",
	defaultChainId: 8453
})

createWeb3Modal({
	ethersConfig,
	chains,
	projectId,
	enableAnalytics: true
})


root.render(
	<BrowserRouter basename={baseUrl}>
		<ConfigProvider theme={{
			algorithm: theme.darkAlgorithm,
			token: {
				colorPrimary: '#1B1D28',
				fontFamily: 'Inter',
				colorLink: '#FFE814',

				fontSize: '14px',

				bgLeftMenu: '#161721',
				bgTopMenu: '#1B1D28',
				bgContent: '#1B1D28',

				textYellow: '#FFE814',
				textGreen: '#86EFAC',
				textBlue: '#BFDBFE',
				grayd9: '#D9D9D9',
				textGray4e: '#4E4E4E',
				textGray74: '#747474',
				textGray9c: '#9CA3AF'
			},
			components: {
				Menu: {
					darkItemSelectedColor: '#fff'
				},
				Button: {
					defaultBorderColor: '#FFE814',
					defaultBg: 'rgba(255, 232, 20, 0.2)'
				},
				Collapse: {
					headerPadding: 0,
					contentPadding: 0,
				},
				Input: {
					paddingBlock: '5px',
					paddingInline: '5px'
				},
				Card: {
					headerHeight: '36px',
					headerBg: '#1B1D28',
					colorBgContainer: '#1B1D28',
				},
				Table: {
					headerBg: '#1B1D28',
					colorBgContainer: '#161721',
				},
				InputNumber: {
					paddingBlock: '2px',
					lineHeight: '26px'
				},
				Slider: {
					handleLineWidth: '1px',
					handleColor: 'transparent',
					handleActiveColor: 'transparent',
					handleSize: '20px',
					handleSizeHover: '20px',
					railSize: '10px'
				},
				Tabs: {
					itemColor: '#fff',
					itemSelectedColor: '#FFE814',
					inkBarColor: '#FFE814',
				},
				Radio: {
					
					defaultBorderColor: '#FFE814',
					buttonCheckedBg: '#161721'
				}
			}
		}}>
			<App />
		</ConfigProvider>
	</BrowserRouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
