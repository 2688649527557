import React from 'react';
import { Avatar } from 'antd';

export const TokenLogo = ({ value, size }) => {

	let url = "";
	if (value?.logo != null) {
		if (value.logo[0] === "/") {
			url = value.logo;
		} else {
			const id = value.logo.substr(7, value.logo.length - 8);
			url = id === "" ? null : `https://ipfs.ape.store/ipfs/${id}?pinataGatewayToken=GHSVOi98-xVWobf-8ocYl0pZQehDgNj_PX5BIktm6Jnhl8BMmMjEA8_1MUFZIcSq`;
		}
	}

	return (	
		<Avatar shape="square" src={url} style={{ width: `${size}px`, height: `${size}px`, minWidth: `${size}px`, minHeight: `${size}px` }}>{value?.symbol}</Avatar>
	);
}