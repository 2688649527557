import React from 'react';

const GridContext = React.createContext();

export const GridProvider = ({ children }) => {
	const [sort, setSort] = React.useState(1);
	const [chain, setChain] = React.useState(0);
	const [order, setOrder] = React.useState(1);
	const [search, setSearch] = React.useState("");
	const [filter, setFilter] = React.useState(0);
	const [page, setPage] = React.useState(0);
	const [pages, setPages] = React.useState(0);

	return (
		<GridContext.Provider value={{ sort, setSort, chain, setChain, order, setOrder, search, setSearch, filter, setFilter, page, setPage, pages, setPages }}>
			{children}
		</GridContext.Provider>
	);
};

export const useGrid = () => {
	return React.useContext(GridContext);
};